<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-back-button text="Retour" :default-href="`/report-sheets/${$route.params.uuidReport}/outsourced-works`"></ion-back-button>
                </ion-buttons>
                
                <ion-title>Rapport : {{ outsourced_work?.report_sheet?.title }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <div :class="$style['sub-header']">Travail sous-traité : {{ outsourced_work.name }}</div>

        <ion-content>
            <ContentSidebarWrapper side="left">
                <template v-slot:content>
                    <div v-if="loading">
                        <EquipmentSkeleton/>
                    </div>
                    <div v-else>
                        <ion-item>
                            <ion-select @ionChange="handleUnity($event)" :disabled="fieldDisabled" :class="$style['custom']" label="Unité :" placeholder="Select" v-model="outsourced_work.unity">
                                <ion-select-option value="Hr">Hr</ion-select-option>
                                <ion-select-option value="Jo">Jo</ion-select-option>
                            </ion-select>
                        </ion-item>

                        <ion-item>
                            <ion-input :disabled="fieldDisabled" :class="$style['custom']" label="Quantité :" placeholder="Enter data" v-model="outsourced_work.quantity"></ion-input>
                        </ion-item>

                        <!-- <ion-item @click="triggerUserSelector">
                            <ion-input :disabled="fieldDisabled" :class="$style['custom']" label="Employé :" placeholder="Select data" v-model="employee"></ion-input>
                        </ion-item> -->

                        <template v-if="!outsourced_work.report_sheet?.outsourced_work_step_validated">
                            <ion-fab slot="fixed" vertical="bottom" horizontal="end">
                                <ion-fab-button color="primary" @click="presentActionSheet">
                                    <ion-icon :icon="caretUp"></ion-icon>
                                </ion-fab-button>
                            </ion-fab>

                            <!-- <UserSelectorButton style="display: none;" ref="userSelectorRef" @change="selectEmployee($event.value)" /> -->
                        </template>
                    </div>
                </template>
            </ContentSidebarWrapper>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import {
        IonBackButton,
        IonButtons,
        IonHeader,
        IonPage,
        IonInput,
        IonIcon,
        IonToolbar,
        actionSheetController,
        alertController,
        IonContent,
        toastController
    } from "@ionic/vue"

    import { defineComponent } from "vue"
    import ContentSidebarWrapper from "@/components/wrappers/ContentSidebarWrapper.vue"
    import { ReportSheet } from "@/report-sheets";
    // import UserSelectorButton from "@/components/data/selectors/UserSelectorButton.vue";
    import { User } from "@/users";
    import EquipmentSkeleton from "@/components/skeletons/EquipmentSkeleton.vue";

    import {
        caretUp
    } from 'ionicons/icons';

    export default defineComponent({
        components: {
            EquipmentSkeleton,
            ContentSidebarWrapper,
            // UserSelectorButton,
            IonBackButton,
            IonButtons,
            IonIcon,
            IonInput,
            IonHeader,
            IonPage,
            IonToolbar,
            IonContent
        },
        setup() {
            return { caretUp }
        },
        data() {
            return {
                fieldDisabled: true,
                loading: false as boolean | false,
                outsourced_work: {
                    unity: '',
                    quantity: '',
                    name: '',
                    // employee_firstname: '',
                    // employee_lastname: '',
                    report_sheet: null as ReportSheet | null
                },
                employee: '' as string | ''
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            handleUnity(event: any) {
                this.outsourced_work.quantity = event.detail.value === 'Hr' ? '8' : '1'
            },
            load() {
                if (this.loading)
                    return

                this.loading = true

                this.axios.get(`/report-sheets/${this.$route.params.uuidReport}/outsourced-works/${this.$route.params.uuidOutsourcedWork}`)
                .then((responses) => {
                    this.outsourced_work = responses.data

                    // this.employee = `${this.outsourced_work?.employee_firstname || ""} ${this.outsourced_work?.employee_lastname || ""}`

                    if (!this.outsourced_work.report_sheet?.outsourced_work_step_validated) 
                        this.fieldDisabled = false
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loading = false
                })
            },
            // selectEmployee(user: User) {
            //     this.employee = `${user.first_name} ${user.last_name}`

            //     this.outsourced_work.employee_firstname = user.first_name ?? ''
            //     this.outsourced_work.employee_lastname = user.last_name ?? ''
            // },
            // triggerUserSelector() {
            //     const userSelector = this.$refs.userSelectorRef as any
                
            //     if (userSelector?.openSelector)
            //         userSelector.openSelector()
            // },
            deleteItem() {
                if (this.loading)
                    return

                this.loading = true
                
                this.axios.delete(`/report-sheets/${this.$route.params.uuidReport}/outsourced-works/${this.$route.params.uuidOutsourcedWork}`)
                .then(async () => {
                    (
                        await toastController.create({
                            header: 'SUCCESS',
                            message: 'Correctly deleted',
                            duration: 5000,
                            color: "success"
                        })
                    ).present()

                    this.$router.replace(`/report-sheets/${this.$route.params.uuidReport}/outsourced-works`)
                }).catch((error) => {
                    console.error(error)
                    this.loading = false
                })
            },
            formSubmit() {
                if (this.loading)
                    return
                
                this.loading = true

                this.axios.put(`/report-sheets/${this.$route.params.uuidReport}/outsourced-works/${this.$route.params.uuidOutsourcedWork}`, {
                    unity: this.outsourced_work?.unity,
                    quantity: this.outsourced_work?.quantity,
                    // employee_firstname: this.outsourced_work?.employee_firstname,
                    // employee_lastname: this.outsourced_work?.employee_lastname
                }).then(async () => {
                    (
                        await toastController.create({
                            header: 'SUCCESS',
                            message: 'Correctly updated',
                            duration: 5000,
                            color: "success"
                        })
                    ).present()
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loading = false
                })
            },
            async presentActionSheet() {
                const actionSheet = await actionSheetController.create({
                    header: `Matériel : ${this.outsourced_work.name}`,
                    buttons: [
                        {
                            text: "Enregistrer",
                            handler: () => {
                                this.formSubmit()
                            }
                        },
                        {
                            text: "Supprimer",
                            role: "destructive",
                            handler: async () => {
                                const alert = await alertController.create({
                                    message: 'Etes-vous sûr de vouloir supprimer définitivement ?',
                                    htmlAttributes: {
                                        'aria-label': 'alert dialog'
                                    },
                                    buttons: [
                                        {
                                            text: 'Annuler',
                                            role: "cancel"
                                        },
                                        {
                                            text: 'Confirmer',
                                            role: "confirm",
                                            handler: () => {
                                                this.deleteItem()
                                            }
                                        }
                                    ]
                                })

                                await alert.present()
                            }
                        },
                        {
                            text: "Annuler",
                            role: "cancel"
                        }
                    ]
                })

                await actionSheet.present()
            }
        }
    })
</script>

<style lang="scss" module>
    .sub-header {
        background: #000;
        height: 50px;
        width: 100%;
        display: grid;
        align-content: center;
        justify-content: center;
    }
    ion-input.custom {
        text-align: right;
    }
</style>