<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-back-button v-if="!loading" :text="$t('report_sheets.top_bar.back_to_steps')" :default-href="`/report-sheets/${$route.params.uuidReport}/employees`"></ion-back-button>
                </ion-buttons>

                <ion-title>{{ $t('report_sheets.top_bar.title') }} : {{ employee?.report_sheet?.title ?? $t('report_sheets.top_bar.loading') }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <div v-if="!loading" :class="$style['sub-header']">{{ $t('report_sheets.employee_item.sub_top_bar_title') }} : {{ employee?.firstname }} {{ employee?.lastname }}</div>

        <ion-content>
            <ContentSidebarWrapper side="left">
                <template v-slot:content>
                    <div v-if="loading">
                        <EquipmentSkeleton/>
                    </div>
                    <div v-else>
                        <ion-item>
                            <ion-input :disabled="fieldDisabled" :label="$t('report_sheets.employee_item.form.labels.activity')" placeholder="Enter data" v-model="employee.activity"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-input :disabled="fieldDisabled" :label="$t('report_sheets.employee_item.form.labels.comment')" placeholder="Enter data" v-model="employee.comment"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-input :disabled="fieldDisabled" type="number" :label="$t('report_sheets.employee_item.form.labels.travel')" placeholder="Enter data" v-model="employee.travel"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-input :disabled="fieldDisabled" type="number" :label="$t('report_sheets.employee_item.form.labels.mobility')" placeholder="Enter data" v-model="employee.mobility"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-select :disabled="fieldDisabled" :label="$t('report_sheets.employee_item.form.labels.mobility_code')" :placeholder="$t('report_sheets.employee_item.form.placeholder.select')" v-model="employee.mobility_code">
                                <ion-select-option v-for="mobility in reportSheetMobilities" :key="mobility.uuid" :value="mobility.uuid">
                                    {{ mobility.label }}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>

                        <ion-item>
                            <ion-select :disabled="fieldDisabled" :label="$t('report_sheets.employee_item.form.labels.other_service')" :placeholder="$t('report_sheets.employee_item.form.placeholder.select')" v-model="employee.services_other" @ionChange="handleChange($event)">
                                <ion-select-option v-for="otherService in reportSheetOtherServices" :key="otherService.uuid" :value="otherService.uuid">
                                    {{ otherService.label }}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>

                        <ion-item>
                            <ion-input :disabled="fieldDisabled" :label="$t('report_sheets.employee_item.form.labels.percent_other_service')" v-model="employee.services_other_percentage_cost" type="number" @change="calculateTotalHoursWorked()"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-input :disabled="fieldDisabled" :label="$t('report_sheets.employee_item.form.labels.start_at')" placeholder="00:00" v-model="employee.start_at" @input="calculateTotalHoursWorked"></ion-input>
                            <ion-input :disabled="fieldDisabled" :label="$t('report_sheets.employee_item.form.labels.end_at')" placeholder="00:00" v-model="employee.end_at" @input="calculateTotalHoursWorked"></ion-input>
                            <ion-input :disabled="fieldDisabled" :label="$t('report_sheets.employee_item.form.labels.total_rest')" placeholder="00:00" v-model="employee.rest_time" @input="calculateTotalHoursWorked"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-input :disabled="true" :label="$t('report_sheets.employee_item.form.labels.total_hour')" v-model="employee.total_hours"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-input :disabled="fieldDisabled" :label="$t('report_sheets.employee_item.form.labels.hour_cost')" v-model="employee.hourly_cost" type="number" @change="calculateTotalDailyCost()"></ion-input>
                            <ion-input :disabled="true" :label="$t('report_sheets.employee_item.form.labels.total_cost')" v-model="employee.total_cost"></ion-input>
                        </ion-item>

                        <template v-if="!employee.report_sheet?.employees_step_validated">
                            <ion-fab slot="fixed" vertical="bottom" horizontal="end">
                                <ion-fab-button color="primary" @click="presentActionSheet">
                                    <ion-icon :icon="caretUp"></ion-icon>
                                </ion-fab-button>
                            </ion-fab>
                        </template>
                    </div>
                </template>
            </ContentSidebarWrapper>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import {
        IonBackButton,
        IonButtons,
        IonHeader,
        IonPage,
        IonInput,
        IonIcon,
        IonToolbar,
        actionSheetController,
        alertController,
        IonContent,
        toastController,
        IonSelect,
        IonSelectOption
    } from "@ionic/vue"

    import { defineComponent } from "vue"
    import ContentSidebarWrapper from "@/components/wrappers/ContentSidebarWrapper.vue"
    import { ReportSheet } from "@/report-sheets";
    import EquipmentSkeleton from "@/components/skeletons/EquipmentSkeleton.vue";

    import {
        caretUp
    } from 'ionicons/icons';

    export default defineComponent({
        components: {
            EquipmentSkeleton,
            ContentSidebarWrapper,
            IonBackButton,
            IonButtons,
            IonIcon,
            IonInput,
            IonHeader,
            IonPage,
            IonToolbar,
            IonContent,
            IonSelect,
            IonSelectOption
        },
        setup() {
            return { caretUp }
        },
        data() {
            return {
                fieldDisabled: true,
                loading: false as boolean | false,
                employee: {
                    activity: '',
                    comment: '',
                    travel: '',
                    mobility: '',
                    start_at: '',
                    end_at: '',
                    rest_time: '',
                    total_hours: '',
                    hourly_cost: '',
                    total_cost: '',
                    mobility_code: '',
                    services_other: '',
                    firstname: '',
                    lastname: '',
                    services_other_percentage_cost: '0.00',
                    report_sheet: null as ReportSheet | null
                },
                reportSheetMobilities: [] as any | [],
                reportSheetOtherServices: [] as any | []
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                if (this.loading)
                    return

                this.loading = true

                Promise.all([
                    this.axios.get(`/report-sheets/${this.$route.params.uuidReport}/employees/${this.$route.params.uuidEmployee}`),
                    this.axios.get(`/report-sheet-mobilities`),
                    this.axios.get(`/report-sheet-other-services`)
                ])
                .then((responses) => {
                    this.employee = responses[0].data
                    this.reportSheetMobilities = responses[1].data
                    this.reportSheetOtherServices = responses[2].data

                    const selectedMobility = this.reportSheetMobilities.find((mobility: any) => this.employee.mobility_code === mobility.label)
                    if (selectedMobility)
                        this.employee.mobility_code = selectedMobility.uuid

                    const selectedOtherService = this.reportSheetOtherServices.find((otherService: any) => this.employee.services_other === otherService.label)
                    if (selectedOtherService)
                        this.employee.services_other = selectedOtherService.uuid

                    // Add a default "Normal" service if none is selected                    
                    this.reportSheetOtherServices.unshift({
                        'label': 'Aucune',
                        'uuid': ''
                    })

                    // Set default select option
                    if (!this.employee.services_other)
                        this.employee.services_other = ''
                        
                    if (!this.employee.report_sheet?.employees_step_validated) 
                        this.fieldDisabled = false

                    this.calculateTotalHoursWorked()
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loading = false
                })
            },
            calculateTotalHoursWorked() {
                if (!this.employee.start_at || !this.employee.end_at) {
                    this.employee.total_hours = "00:00"
                    return
                }

                const timeToMinutes = (time: any) => {
                    if (!time || !time.includes(':')) return 0 // Return 0 if the field is empty or incorrectly formatted
                    const [hours, minutes] = time.split(':').map(Number)
                    return (hours * 60) + minutes
                }

                const startMinutes = timeToMinutes(this.employee.start_at)
                const endMinutes = timeToMinutes(this.employee.end_at)
                const restMinutes = timeToMinutes(this.employee.rest_time || "00:00") // Default rest time to 00:00 if empty

                // Ensure values are valid numbers
                if (isNaN(startMinutes) || isNaN(endMinutes) || isNaN(restMinutes)) {
                    this.employee.total_hours = "00:00"
                    return
                }

                const workedMinutes = endMinutes - startMinutes - restMinutes

                if (workedMinutes < 0) {
                    this.employee.total_hours = "00:00" // Prevent negative total
                    return
                }

                const hours = Math.floor(workedMinutes / 60)
                const minutes = workedMinutes % 60

                this.employee.total_hours = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`

                this.calculateTotalDailyCost()
            },
            calculateTotalDailyCost() {
                const totalHours = this.employee.total_hours ? parseFloat(this.employee.total_hours.replace(":", ".")) : 0
                const hourlyCost = this.employee.hourly_cost ? parseFloat(this.employee.hourly_cost) : 0
                const servicesOtherPercentageCost = this.employee.services_other_percentage_cost ? parseFloat(this.employee.services_other_percentage_cost) : 0

                let totalCost = totalHours * hourlyCost
                totalCost -= totalCost * (servicesOtherPercentageCost / 100)
                this.employee.total_cost = totalCost.toFixed(2)
            },
            async presentActionSheet() {
                const actionSheet = await actionSheetController.create({
                    header: `${this.$t('report_sheets.employee_item.sub_top_bar_title')} : ${this.employee.firstname } - ${this.employee.lastname}`,
                    buttons: [
                        {
                            text: this.$t('report_sheets.employee_item.actions.buttons.save'),
                            handler: () => {
                                this.formSubmit()
                            }
                        },
                        {
                            text: this.$t('report_sheets.employee_item.actions.buttons.delete'),
                            role: "destructive",
                            handler: async () => {
                                const alert = await alertController.create({
                                    message: 'Etes-vous sûr de vouloir supprimer définitivement ?',
                                    htmlAttributes: {
                                        'aria-label': 'alert dialog'
                                    },
                                    buttons: [
                                        {
                                            text: this.$t('report_sheets.employee_item.actions.buttons.cancel'),
                                            role: "cancel"
                                        },
                                        {
                                            text: this.$t('report_sheets.employee_item.actions.buttons.confirm'),
                                            role: "confirm",
                                            handler: () => {
                                                this.deleteItem()
                                            }
                                        }
                                    ]
                                })

                                await alert.present()
                            }
                        },
                        {
                            text: this.$t('report_sheets.employee_item.actions.buttons.cancel'),
                            role: "cancel"
                        }
                    ]
                })

                await actionSheet.present()
            },
            deleteItem() {
                if (this.loading)
                    return

                this.loading = true
                
                this.axios.delete(`/report-sheets/${this.$route.params.uuidReport}/employees/${this.$route.params.uuidEmployee}`)
                .then(async () => {
                    (
                        await toastController.create({
                            header: this.$t('report_sheets.employee_item.actions.validations.delete.title'),
                            message: this.$t('report_sheets.employee_item.actions.validations.delete.message'),
                            duration: 5000,
                            color: "success"
                        })
                    ).present()

                    this.$router.replace(`/report-sheets/${this.$route.params.uuidReport}/employees`)
                }).catch((error) => {
                    console.error(error)
                    this.loading = false
                })
            },
            formSubmit() {
                if (this.loading)
                    return
                
                this.loading = true

                this.axios.put(`/report-sheets/${this.$route.params.uuidReport}/employees/${this.$route.params.uuidEmployee}`, {
                    activity: this.employee.activity,
                    comment: this.employee.comment,
                    travel: this.employee.travel,
                    mobility: this.employee.mobility,
                    start_at: this.employee.start_at ?? '00:00',
                    end_at: this.employee.end_at ?? '00:00',
                    rest_time: this.employee.rest_time ?? '00:00',
                    total_hours: this.employee.total_hours ?? '00:00',
                    hourly_cost: this.employee.hourly_cost ?? 0,
                    mobility_code: this.employee.mobility_code,
                    services_other: this.employee.services_other,
                    services_other_percentage_cost: this.employee.services_other_percentage_cost
                }).then(async () => {
                    (
                        await toastController.create({
                            header: this.$t('report_sheets.employee_item.actions.validations.save.title'),
                            message: this.$t('report_sheets.employee_item.actions.validations.save.message'),
                            duration: 5000,
                            color: "success"
                        })
                    ).present()
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loading = false
                })
            },
            handleChange(event: any) {
                const otherServiceSelected = this.reportSheetOtherServices.find((otherService: any) => otherService.uuid === event.detail.value)
                this.employee.services_other_percentage_cost = otherServiceSelected.percentage_cost

                if (otherServiceSelected.uuid == '')
                    this.employee.services_other_percentage_cost = '0.00'

                this.calculateTotalDailyCost()
            }
        }
    })
</script>

<style lang="scss" module>
    .sub-header {
        background: #000;
        height: 50px;
        width: 100%;
        display: grid;
        align-content: center;
        justify-content: center;
    }
    ion-input.custom {
        text-align: right;
    }
</style>