import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ReportSheetsSkeleton = _resolveComponent("ReportSheetsSkeleton")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ContentSidebarWrapper = _resolveComponent("ContentSidebarWrapper")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_UserSelectorButton = _resolveComponent("UserSelectorButton")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  (_ctx.loaded)
                    ? (_openBlock(), _createBlock(_component_ion_back_button, {
                        key: 0,
                        text: _ctx.$t('report_sheets.top_bar.back_to_steps'),
                        "default-href": `/report-sheets/${_ctx.sheet?.uuid}`
                      }, null, 8, ["text", "default-href"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.top_bar.title')) + " : " + _toDisplayString(_ctx.sheet?.title ?? _ctx.$t('report_sheets.top_bar.loading')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.loaded)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style['sub-header'])
          }, _toDisplayString(_ctx.$t('report_sheets.employees_page.sub_top_bar_title')) + " (" + _toDisplayString(_ctx.sheet?.employees.length ?? 0) + ")", 3))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ContentSidebarWrapper, { side: "left" }, {
            content: _withCtx(() => [
              (!_ctx.loaded)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_ReportSheetsSkeleton)
                  ]))
                : (_ctx.sheet?.employees.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sheet?.employees, (employee) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: employee.uuid,
                          button: true,
                          onClick: ($event: any) => (_ctx.$router.push(`/report-sheets/${_ctx.sheet?.uuid}/employees/${employee?.uuid}`))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style['inner'])
                            }, [
                              _createElementVNode("div", null, [
                                _createElementVNode("h4", null, _toDisplayString(employee?.firstname) + " - " + _toDisplayString(employee?.lastname), 1),
                                (!_ctx.user.sites_supervisor?.includes(_ctx.sheet.site_id))
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('report_sheets.employees_page.employee_total')) + " : " + _toDisplayString(employee.total_cost) + "€", 1))
                                  : _createCommentVNode("", true)
                              ])
                            ], 2)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128)),
                      (!_ctx.user.sites_supervisor?.includes(_ctx.sheet.site_id))
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['total_day'])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_note, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.employees_page.employee_total')) + " : " + _toDisplayString(_ctx.total_day) + "€", 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["class"]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_ion_text, {
                        class: "ion-text-center",
                        color: "medium"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('report_sheets.employees_page.no_result')), 1)
                        ]),
                        _: 1
                      })
                    ]))
            ]),
            _: 1
          }),
          (!_ctx.sheet?.employees_step_validated)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_ion_fab, {
                  slot: "fixed",
                  vertical: "bottom",
                  horizontal: "end"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_fab_button, {
                      color: "primary",
                      onClick: _ctx.presentActionSheet
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.caretUp }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_UserSelectorButton, {
                  style: {"display":"none"},
                  ref: "userSelectorRef",
                  onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addUser($event.value)))
                }, null, 512)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}