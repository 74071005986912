<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-back-button text="Retour" :default-href="`/report-sheets/${sheet?.uuid}`"></ion-back-button>
                </ion-buttons>
                <ion-title>Rapport : {{ sheet?.title }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <div :class="$style['sub-header']">Travaux sous-traités ({{ sheet?.outsourced_works.length ?? 0 }})</div>

        <ion-content>
            <ContentSidebarWrapper side="left">
                <template v-slot:content>
                    <div v-if="!loaded">
                        <EquipmentSkeleton/>
                    </div>
                    <div v-else-if="sheet?.outsourced_works?.length">
                        <ion-item v-for="outsourcedWork in sheet?.outsourced_works" :key="outsourcedWork.uuid" :button="true" @click="$router.push('/report-sheets/' + sheet.uuid + '/outsourced-works/' + outsourcedWork.uuid)">
                            <div :class="$style['inner']">
                                <div>
                                    <h4>{{ outsourcedWork?.name }}</h4> 
                                </div>
                            </div>
                        </ion-item>
                    </div>
                    <div v-else>
                        <ion-text class="ion-text-center" color="medium">
                            <p>Aucun résultat</p>
                        </ion-text>
                    </div>
                </template>
            </ContentSidebarWrapper>

            <template v-if="!sheet?.outsourced_work_step_validated">
                <ion-fab slot="fixed" vertical="bottom" horizontal="end">
                    <ion-fab-button color="primary" @click="presentActionSheet">
                        <ion-icon :icon="caretUp"></ion-icon>
                    </ion-fab-button>
                </ion-fab>

                <!-- <EquipmentSelectorButton style="display: none;" ref="locationselectorRef" @change="addLocation($event.value)"/> -->
            </template>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import {
        IonBackButton,
        IonButtons,
        IonHeader,
        IonPage,
        IonToolbar,
        IonItem,
        IonContent,
        toastController,
        IonFab,
        IonFabButton,
        actionSheetController,
        alertController,
        IonIcon
    } from "@ionic/vue"

    import {
        caretUp
    } from 'ionicons/icons'

    import { defineComponent } from "vue"
    import EquipmentSkeleton from "@/components/skeletons/EquipmentSkeleton.vue";
    import ContentSidebarWrapper from "@/components/wrappers/ContentSidebarWrapper.vue"
    import { ReportSheet } from "@/report-sheets"
    // import EquipmentSelectorButton from "@/components/data/selectors/EquipmentSelectorButton.vue"
    import { useUserStore } from "@/stores/user"

    export default defineComponent({
        components: {
            // EquipmentSelectorButton,
            EquipmentSkeleton,
            ContentSidebarWrapper,
            IonBackButton,
            IonButtons,
            IonHeader,
            IonItem,
            IonPage,
            IonToolbar,
            IonContent,
            IonFab,
            IonFabButton,
            IonIcon
        },
        setup() {
            const user = useUserStore()

            return { 
                caretUp,
                user 
            }
        },
        data() {
            return {
                loaded: false as boolean | false,
                sheet: null as ReportSheet | null
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.axios.get(`/report-sheets/${this.$route.params.uuidReport}`)
                .then((responses) => {
                    this.sheet = responses.data
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loaded = true
                })
            },
            // addLocation(equipment: Equipment) {
            //     this.loaded = false

            //     this.axios.post(`/report-sheets/${this.$route.params.uuidReport}/outsourced-works`, {
            //         locationUuid: equipment.uuid
            //     }).then(async (response) => {
            //         (
            //             await toastController.create({
            //                 header: 'SUCCESS',
            //                 message: 'Location added',
            //                 duration: 5000,
            //                 color: "success"
            //             })
            //         ).present()

            //         this.$router.push(`/report-sheets/${this.$route.params.uuidReport}/outsourced-works/${response.data.uuid}`)
            //     }).catch((error) => {
            //         console.error(error)
            //         this.loaded = true
            //     })
            // },
            stepValidation() {
                this.loaded = false

                this.axios.put(`/report-sheets/${this.$route.params.uuidReport}/step-validation`, {
                    step: 'outsourced_work_step_validated'
                }).then(async () => {
                    (
                        await toastController.create({
                            header: 'SUCCESS',
                            message: 'Outsourced work step validated',
                            duration: 5000,
                            color: "success"
                        })
                    ).present()

                    if (this.sheet && this.user.sites_supervisor?.includes(this.sheet.site_id))
                        this.$router.push(`/report-sheets/${this.$route.params.uuidReport}/employees`)
                    else
                        this.$router.push(`/report-sheets/${this.$route.params.uuidReport}`)
                }).catch((error) => {
                    console.error(error)
                    this.loaded = true
                })
            },
            // triggerLocationSelector() {
            //     const locationSelector = this.$refs.locationselectorRef as any

            //     if (locationSelector?.openSelector)
            //         locationSelector.openSelector()
            // },
            async presentActionSheet() {
                const actionSheet = await actionSheetController.create({
                    buttons: [
                        // {
                        //     text: "Ajouter une location",
                        //     handler: () => {
                        //         this.triggerLocationSelector()
                        //     }
                        // },
                        {
                            text: "Valider cette étape",
                            handler: async () => {
                                const alert = await alertController.create({
                                    message: 'Etes-vous sûr de vouloir valider cette étape ?',
                                    htmlAttributes: {
                                        'aria-label': 'alert dialog'
                                    },
                                    buttons: [
                                        {
                                            text: 'Annuler',
                                            role: "cancel"
                                        },
                                        {
                                            text: 'Confirmer',
                                            role: "confirm",
                                            handler: () => {
                                                this.stepValidation()
                                            }
                                        }
                                    ]
                                })

                                await alert.present()
                            }
                        },
                        {
                            text: "Annuler",
                            role: "cancel"
                        }
                    ]
                })

                await actionSheet.present()
            }
        }
    })
</script>

<style lang="scss" module>
    .sub-header {
        background: #000;
        height: 50px;
        width: 100%;
        display: grid;
        align-content: center;
        justify-content: center;
    }
    .inner {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1em;
        font-size: .9em;

        @media(max-width: 990px) {
            font-size: .8em;
        }
        @media(max-width: 700px) {
            flex-direction: column
        }
        h4, p {
            padding: 0;
            margin: 0;
        }
        p {
            margin-top: .25em;
            color: var(--ion-color-medium);
        }
        .right {
            display: flex;
            align-items: center;

            @media(min-width: 701px) {
                flex-direction: column;
                align-items: flex-end;
            }

            @media(max-width: 700px) {
                .date {
                    &:before {
                        content: '—';
                        margin: 0 .5em;
                        margin-top: 0;
                    }
                }
            }
        }
    }
</style>