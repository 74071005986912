<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-back-button v-if="loaded" :text="$t('report_sheets.top_bar.back_to_steps')" :default-href="`/report-sheets/${report_sheet?.uuid}`"></ion-back-button>
                </ion-buttons>
                
                <ion-title>{{ $t('report_sheets.top_bar.title') }} : {{ report_sheet?.title ?? $t('report_sheets.top_bar.loading') }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <div v-if="!loaded">
                <EquipmentSkeleton/>
            </div>
            <template v-else>
                <div :class="$style['annexes-wrapper']">
                    <div>
                        <h1>{{ $t('report_sheets.annexes_page.validation.confirmation_title') }}</h1>

                        <ul :class="$style['buttons-wrapper']">
                            <li><ion-button @click="presentActionSheet()">{{ $t('report_sheets.annexes_page.validation.btn_confirm') }}</ion-button></li>
                        </ul>
                    </div>
                </div>
            </template>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import { defineComponent } from "vue"
    import {
        IonContent,
        IonHeader,
        IonPage,
        toastController,
        IonBackButton,
        IonTitle,
        IonToolbar,
        IonButton,
        alertController
    } from "@ionic/vue"

    import { ReportSheet } from "@/report-sheets"
    import { useUserStore } from "@/stores/user"
    import EquipmentSkeleton from "@/components/skeletons/EquipmentSkeleton.vue"

    export default defineComponent({
        components: {
            IonContent,
            IonHeader,
            IonTitle,
            IonToolbar,
            IonPage,
            IonBackButton,
            EquipmentSkeleton,
            IonButton
        },
        setup() {
            const user = useUserStore()

            return {
                user
            }
        },
        data() {
            return {
                loaded: false,
                report_sheet: null as ReportSheet | null
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.loaded = false
                
                this.axios.get(`/report-sheets/${this.$route.params.uuidReport}`)
                .then(response => {
                    this.report_sheet = response.data

                    if (this.report_sheet?.annexes_step_validated)
                        this.$router.push(`/report-sheets/${this.$route.params.uuidReport}`)
                }).catch(error => {
                    console.error(error)
                }).finally(() => {
                    this.loaded = true
                })
            },
            async presentActionSheet() {
                const alert = await alertController.create({
                    message: this.$t('report_sheets.annexes_page.alert.title'),
                    htmlAttributes: {
                        'aria-label': 'alert dialog'
                    },
                    buttons: [
                        {
                            text: this.$t('report_sheets.annexes_page.alert.btn_cancel'),
                            role: "cancel"
                        },
                        {
                            text: this.$t('report_sheets.annexes_page.alert.btn_confirm'),
                            role: "confirm",
                            handler: () => {
                                this.stepValidation()
                            }
                        }
                    ]
                })

                await alert.present()
            },
            stepValidation() {
                this.loaded = false

                this.axios.put(`/report-sheets/${this.$route.params.uuidReport}/step-validation`, {
                    step: 'annexes_step_validated'
                }).then(async () => {
                    (
                        await toastController.create({
                            header:  this.$t('report_sheets.annexes_page.validation.success_title'),
                            message:  this.$t('report_sheets.annexes_page.validation.success_message'),
                            duration: 5000,
                            color: "success"
                        })
                    ).present()

                    this.goToNextStepRoleDepending()
                }).catch(error => {
                    console.error(error)
                    this.loaded = true
                })
            },
            goToNextStepRoleDepending() {
                if (this.report_sheet && this.user.sites_supervisor?.includes(this.report_sheet.site_id))
                    this.$router.push(`/report-sheets/${this.$route.params.uuidReport}/equipments`)
                else
                    this.$router.push(`/report-sheets/${this.$route.params.uuidReport}`)
            }
        }
    })
</script>

<style lang="scss" module>
    .sub-header {
        background: #000;
        height: 50px;
        width: 100%;
        display: grid;
        align-content: center;
        justify-content: center;
    }
    .annexes-wrapper {
        padding: 25px;

        h1 {
            text-align: center;
            margin-bottom: 50px;
        }
    }
    .buttons-wrapper {
        list-style: none;
        display: flex;
        align-items: center;
        justify-self: center;
        padding: 0;

        li {
            &:first-child {
                margin: 0 50px 0 0;
            }
            &:last-child {
                margin: 0;
            }
        }
    }
</style>