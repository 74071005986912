<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-back-button v-if="loaded" :text="$t('report_sheets.top_bar.back_to_steps')" :default-href="`/report-sheets/${sheet?.uuid}`"></ion-back-button>
                </ion-buttons>
                <ion-title>{{ $t('report_sheets.top_bar.title') }} : {{ sheet?.title ?? $t('report_sheets.top_bar.loading') }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <div v-if="loaded" :class="$style['sub-header']">{{ $t('report_sheets.employees_page.sub_top_bar_title') }} ({{ sheet?.employees.length ?? 0 }})</div>

        <ion-content>
            <ContentSidebarWrapper side="left">
                <template v-slot:content>
                    <div v-if="!loaded">
                        <ReportSheetsSkeleton/>
                    </div>
                    <div v-else-if="sheet?.employees.length">
                        <ion-item v-for="employee in sheet?.employees" :key="employee.uuid" :button="true" @click="$router.push(`/report-sheets/${sheet?.uuid}/employees/${employee?.uuid}`)">
                            <div :class="$style['inner']">
                                <div>
                                    <h4>{{ employee?.firstname }} - {{ employee?.lastname }}</h4> 
                                    <p v-if="!user.sites_supervisor?.includes(sheet.site_id)">{{ $t('report_sheets.employees_page.employee_total') }} : {{ employee.total_cost }}€</p>
                                </div>
                            </div>
                        </ion-item>

                        <ion-item :class="$style['total_day']" v-if="!user.sites_supervisor?.includes(sheet.site_id)">
                            <ion-note>{{ $t('report_sheets.employees_page.employee_total') }} : {{ total_day }}€</ion-note>
                        </ion-item>
                    </div>
                    <div v-else>
                        <ion-text class="ion-text-center" color="medium">
                            <p>{{ $t('report_sheets.employees_page.no_result') }}</p>
                        </ion-text>
                    </div>
                </template>
            </ContentSidebarWrapper>

            <template v-if="!sheet?.employees_step_validated">
                <ion-fab slot="fixed" vertical="bottom" horizontal="end">
                    <ion-fab-button color="primary" @click="presentActionSheet">
                        <ion-icon :icon="caretUp"></ion-icon>
                    </ion-fab-button>
                </ion-fab>

                <UserSelectorButton style="display: none;" ref="userSelectorRef" @change="addUser($event.value)" />
            </template>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import {
        IonBackButton,
        IonButtons,
        IonHeader,
        IonPage,
        IonToolbar,
        IonContent,
        toastController,
        IonFab,
        IonFabButton,
        actionSheetController,
        alertController,
        IonIcon
    } from "@ionic/vue"

    import {
        caretUp
    } from "ionicons/icons"

    import { defineComponent } from "vue"
    import ReportSheetsSkeleton from "@/components/skeletons/ReportSheetsSkeleton.vue";
    import ContentSidebarWrapper from "@/components/wrappers/ContentSidebarWrapper.vue"
    import { ReportSheet } from "@/report-sheets"
    import UserSelectorButton from "@/components/data/selectors/UserSelectorButton.vue"
    import { User } from "@/users"
    import { useUserStore } from "@/stores/user"

    export default defineComponent({
        components: {
            ContentSidebarWrapper,
            ReportSheetsSkeleton,
            UserSelectorButton,
            IonBackButton,
            IonButtons,
            IonHeader,
            IonPage,
            IonToolbar,
            IonContent,
            IonFab,
            IonIcon,
            IonFabButton
        },
        setup() {
            const user = useUserStore()

            return { 
                caretUp,
                user 
            }
        },
        data() {
            return {
                loaded: false,
                sheet: null as ReportSheet | null,
                total_day: 0
            };
        },
        methods: {
            load() {
                this.axios.get(`/report-sheets/${this.$route.params.uuidReport}`, {
                }).then((responses) => {
                    this.sheet = responses.data
                    this.calculateTotalDay()
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loaded = true
                })
            },
            calculateTotalDay() {
                this.total_day = this.sheet?.employees.reduce((sum: number, employee: { total_cost: string }) => {
                    return sum + (parseFloat(employee.total_cost) || 0)
                }, 0)
            },
            addUser(user: User) {
                this.loaded = false

                this.axios.post(`/report-sheets/${this.$route.params.uuidReport}/employees`, {
                    employeeUuid: user.uuid
                }).then(async (response) => {
                    (
                        await toastController.create({
                            header: this.$t('report_sheets.employees_page.toast.employee.success'),
                            message: this.$t('report_sheets.employees_page.toast.employee.message'),
                            duration: 5000,
                            color: "success"
                        })
                    ).present()

                    this.sheet?.employees.push(response.data)
                    this.$router.push(`/report-sheets/${this.$route.params.uuidReport}/employees/${response.data.uuid}`)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loaded = true
                })
            },
            stepValidation() {
                this.loaded = false

                this.axios.put(`/report-sheets/${this.$route.params.uuidReport}/step-validation`, {
                    step: 'employees_step_validated'
                }).then(async () => {
                    (
                        await toastController.create({
                            header: this.$t('report_sheets.employees_page.toast.validation.success'),
                            message: this.$t('report_sheets.employees_page.toast.validation.message'),
                            duration: 5000,
                            color: "success"
                        })
                    ).present()

                    this.$router.push(`/report-sheets/${this.$route.params.uuidReport}`)
                }).catch((error) => {
                    console.error(error)
                    this.loaded = true
                })
            },
            triggerUserSelector() {
                const userSelector = this.$refs.userSelectorRef as any

                if (userSelector?.openSelector)
                    userSelector.openSelector()
            },
            async presentActionSheet() {
                const actionSheet = await actionSheetController.create({
                    buttons: [
                        {
                            text: this.$t('report_sheets.employees_page.popup.add_employee'),
                            handler: () => {
                                this.triggerUserSelector()
                            }
                        },
                        {
                            text: this.$t('report_sheets.employees_page.popup.step_validation'),
                            handler: async () => {
                                const alert = await alertController.create({
                                    message: this.$t('report_sheets.employees_page.alert.title'),
                                    htmlAttributes: {
                                        'aria-label': 'alert dialog'
                                    },
                                    buttons: [
                                        {
                                            text: this.$t('report_sheets.employees_page.alert.btn_cancel'),
                                            role: "cancel"
                                        },
                                        {
                                            text: this.$t('report_sheets.employees_page.alert.btn_confirm'),
                                            role: "confirm",
                                            handler: () => {
                                                this.stepValidation()
                                            }
                                        }
                                    ]
                                })

                                await alert.present()
                            }
                        },
                        {
                            text: this.$t('report_sheets.employees_page.popup.btn_cancel'),
                            role: "cancel"
                        }
                    ]
                })

                await actionSheet.present()
            }
        },
        mounted() {
            this.load()
        }
    })
</script>

<style lang="scss" module>
    .sub-header {
        background: #000;
        height: 50px;
        width: 100%;
        display: grid;
        align-content: center;
        justify-content: center;
    }
    .inner {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1em;
        font-size: .9em;

        @media(max-width: 990px) {
            font-size: .8em;
        }
        @media(max-width: 700px) {
            flex-direction: column
        }
        h4, p {
            padding: 0;
            margin: 0;
        }
        p {
            margin-top: .25em;
            color: var(--ion-color-medium);
        }
        .right {
            display: flex;
            align-items: center;

            @media(min-width: 701px) {
                flex-direction: column;
                align-items: flex-end;
            }

            @media(max-width: 700px) {
                .date {
                    &:before {
                        content: '—';
                        margin: 0 .5em;
                        margin-top: 0;
                    }
                }
            }
        }
    }
    .total_day ion-note {
        font-weight: bold;
        color: var(--ion-color-primary);
    }
</style>