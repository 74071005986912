<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>

                <ion-title>{{ $t('report_sheets.main_page.screen_title') }}</ion-title>

                <ion-buttons slot="secondary">
                    <ion-button @click="reload()">
                        <ion-icon :icon="refreshIcon" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>

            <ion-toolbar v-if="is_mobile" color="primary">
                <ion-searchbar
                    v-model="search"
                    :placeholder="$t('report_sheets.main_page.filters.search_placeholder')"
                ></ion-searchbar>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <ContentSidebarWrapper :class="is_mobile ? $style['custom-height'] : ''" side="left">
                <template v-slot:content>
                    <div v-if="loaded">
                        <ReportSheetsSkeleton />
                    </div>
                    <div v-else-if="reportSheets.length">
                        <ion-item v-for="reportSheet in reportSheets" :key="reportSheet.uuid" :sheet="reportSheet" :button="true" @click="nextPageIfSiteManager(reportSheet)">
                            <div :class="$style['inner']">
                                <div>
                                    <h4>{{ reportSheet.title }}</h4>
                                    <p v-if="!user.sites_supervisor?.includes(reportSheet.site_id)">{{ $t('report_sheets.main_page.total') }} : {{ reportSheet.total_cost }}€</p>
                                </div>

                                <div :class="$style['right']">
                                    <p v-if="reportSheet.status == 'pending'">{{ $t('report_sheets.main_page.filters.status.options.pending') }}</p>

                                    <template v-if="reportSheet.status == 'validated'">
                                        <p :class="$style['raport-validated']">{{ $t('report_sheets.main_page.filters.status.options.validated') }}</p>
                                        <p>{{ moment(reportSheet.validated_at).format("DD/MM/YYYY") }}</p>
                                    </template>
                                </div>
                            </div>
                        </ion-item>

                        <ion-infinite-scroll
                            id="infinite-scroll"
                            threshold="500px"
                            @ionInfinite="loadNextPage($event)"
                        >
                            <ion-infinite-scroll-content></ion-infinite-scroll-content>
                        </ion-infinite-scroll>
                    </div>
                    <div v-else>
                        <ion-text class="ion-text-center" color="medium">
                            <p>{{ $t('report_sheets.main_page.no_result') }}</p>
                        </ion-text>
                    </div>
                </template>

                <template v-slot:sidebar>
                    <div :class="$style['filters-content']">
                        <div
                            v-if="!is_mobile"
                            :class="$style['filter-item-wrapper']"
                            class=""
                        >
                            <ion-searchbar
                                v-model="search"
                                :class="$style['filter-item-search']"
                                :placeholder="$t('report_sheets.main_page.filters.search_placeholder')"
                                color="light"
                                mode="ios"
                            ></ion-searchbar>
                        </div>

                        <div :class="$style['filter-item-wrapper']">
                            <div :class="$style['filter-item']">
                                <ion-select
                                    v-model="status"
                                    :multiple="true"
                                    :placeholder="$t('report_sheets.main_page.filters.status.label')"
                                    style="width: 100%"
                                >
                                    <ion-select-option value="pending">{{ $t('report_sheets.main_page.filters.status.options.pending') }}</ion-select-option>
                                    <ion-select-option value="validated">{{ $t('report_sheets.main_page.filters.status.options.validated') }}</ion-select-option>
                                </ion-select>
                            </div>
                        </div>
                    </div>
                </template>
            </ContentSidebarWrapper>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import ContentSidebarWrapper from "@/components/wrappers/ContentSidebarWrapper.vue";
    import ReportSheetsSkeleton from "@/components/skeletons/ReportSheetsSkeleton.vue";
    import { defineComponent } from "vue"
    import {
        IonButtons,
        IonContent,
        IonHeader,
        IonMenuButton,
        IonPage,
        IonText,
        IonTitle,
        IonSearchbar,
        IonSelect,
        IonSelectOption,
        IonInfiniteScroll,
        IonInfiniteScrollContent,
        IonToolbar,
        InfiniteScrollCustomEvent
    } from "@ionic/vue"
    
    import {
        reload as refreshIcon,
    } from "ionicons/icons";

    import { ReportSheet } from "@/report-sheets"
    import moment from 'moment';
    import { useUserStore } from "@/stores/user"

    export default defineComponent({
        components: {
            IonTitle, IonToolbar, IonButtons, ReportSheetsSkeleton,
            IonContent, IonText,
            ContentSidebarWrapper, IonPage, IonMenuButton, IonHeader,
            IonSearchbar, IonInfiniteScroll, IonInfiniteScrollContent,
            IonSelect, IonSelectOption
        },
        setup() {
            const user = useUserStore()

            return {
                moment,
                user,
                refreshIcon
            }
        },
        data() {
            return {
                search: "" as string,
                status: [] as string[],
                search_timeout: null as null | number,
                page: 1,
                loaded: false,
                reportSheets: [] as ReportSheet[]
            }
        },
        computed: {
            is_mobile() {
                return window?.innerWidth < 768;
            }
        },
        mounted() {
            this.load()
        },
        watch: {
            search: function () {
                if (this.search_timeout)
                    clearTimeout(this.search_timeout)

                this.search_timeout = setTimeout(() => {
                    this.load(true)
                }, 750)
            },
            status: function () {
                this.load(true)
            }
        },
        methods: {
            reload() {
                this.search = ""
                this.status = []
                this.load(true)
            },
            load(refresh = false) {
                this.loaded = true

                if (refresh)
                    this.page = 1

                this.axios.get('/report-sheets', {
                    params: {
                        page: this.page,
                        search: this.search,
                        status: this.status
                    }
                })
                .then(response => {
                    if (refresh)
                        this.reportSheets = []

                    response.data.data.forEach((reportSheet: ReportSheet) => {
                        this.reportSheets.push(reportSheet)
                    })

                    this.page++
                }).catch(error => {
                    console.error(error)
                }).finally(() => {
                    this.loaded = false
                })
            },
            loadNextPage(e: InfiniteScrollCustomEvent) {
                this.axios.get('/report-sheets', {
                    params: {
                        page: this.page,
                        search: this.search,
                        status: this.status
                    }
                })
                .then(response => {
                    response.data.data.forEach((reportSheet: ReportSheet) => {
                        this.reportSheets.push(reportSheet)
                    })

                    this.page++

                    if (this.page > response.data.last_page)
                        e.target.disabled = true

                    e.target.complete()
                }).catch(error => {
                    console.error(error)
                })
            },
            nextPageIfSiteManager(reportSheet: ReportSheet) {
                const firstAnnexUuid = reportSheet.annexes?.[0]?.uuid ?? null;
                
                if (this.user.sites_supervisor?.includes(reportSheet.site_id) && reportSheet.status != 'validated')
                    this.$router.push(`/report-sheets/${reportSheet.uuid}/annexes/${firstAnnexUuid}`)
                else
                    this.$router.push(`/report-sheets/${reportSheet.uuid}`)
            }
        }
    })
</script>

<style lang="scss" module>
    .filters-content {
        padding-top: 1em;
        padding-bottom: 1em;
    }

    $filter-icon-space: 2.5em;
    $filter-icon-width: 1.4em;

    .filter-item-wrapper {
        padding: 0.25em 1em;

        ion-searchbar,
        ion-button,
        ion-select {
            font-size: 1em !important;
            --box-shadow: none;

            input,
            ion-icon {
            font-size: 1em !important;
            }
        }

        ion-select {
            --padding-start: #{$filter-icon-space};
        }

        ion-searchbar,
        .button-inner {
            input {
            padding-inline-start: $filter-icon-space !important;
            }

            ion-icon {
            width: $filter-icon-width !important;
            margin-left: ($filter-icon-space - $filter-icon-width) * 0.5 !important;
            margin-right: ($filter-icon-space - $filter-icon-width) * 0.5 !important;
            left: 0 !important;
            }
        }

        .filter-item,
        .filter-item-search {
            --border-radius: 10px;
            border-radius: var(--border-radius);
            padding: 0;
            margin: 0;
            padding-inline-start: 0;
            padding-inline-end: 0;
            display: flex;
            background: var(--ion-color-light);
            color: var(--ion-color-light-contrast);
            height: 2.75em;

            overflow: hidden;

            > * {
                height: inherit !important;
                margin: 0;
                margin-inline: 0;

                &:first-child {
                    font-weight: normal;
                    flex-grow: 1;
                }
            }

            ion-button {
            --padding-start: 0;
            }

            .button-inner {
                text-align: left;
                width: 100%;
                display: flex;
                align-items: center;

                ion-icon {
                    width: $filter-icon-width !important;
                }

                > span {
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .remove-filter {
                    margin-right: -0.6em !important;
                    width: 1em !important;
                    font-size: 1.2em !important;
                    }
                }
            }
        }
    }
    .inner {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1em;
        font-size: .9em;

        @media(max-width: 990px) {
            font-size: .8em;
        }
        @media(max-width: 700px) {
            flex-direction: column
        }
        h4, p {
            padding: 0;
            margin: 0;
        }
        p {
            margin-top: .25em;
            color: var(--ion-color-medium);
        }
        .right {
            display: flex;
            align-items: center;

            @media(min-width: 701px) {
                flex-direction: column;
                align-items: flex-end;
            }

            @media(max-width: 700px) {
                .date {
                    &:before {
                        content: '—';
                        margin: 0 .5em;
                        margin-top: 0;
                    }
                }
            }
        }
    }
    .raport-validated {
        color: var(--ion-color-success) !important;
        font-weight: bold;
    }
    .custom-height {
        min-height: initial;
    }
</style>