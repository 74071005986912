import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ReportSheetsSkeleton = _resolveComponent("ReportSheetsSkeleton")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ContentSidebarWrapper = _resolveComponent("ContentSidebarWrapper")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.main_page.screen_title')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "secondary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reload()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.refreshIcon }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.is_mobile)
            ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                key: 0,
                color: "primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_searchbar, {
                    modelValue: _ctx.search,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
                    placeholder: _ctx.$t('report_sheets.main_page.filters.search_placeholder')
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ContentSidebarWrapper, {
            class: _normalizeClass(_ctx.is_mobile ? _ctx.$style['custom-height'] : ''),
            side: "left"
          }, {
            content: _withCtx(() => [
              (_ctx.loaded)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_ReportSheetsSkeleton)
                  ]))
                : (_ctx.reportSheets.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reportSheets, (reportSheet) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: reportSheet.uuid,
                          sheet: reportSheet,
                          button: true,
                          onClick: ($event: any) => (_ctx.nextPageIfSiteManager(reportSheet))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style['inner'])
                            }, [
                              _createElementVNode("div", null, [
                                _createElementVNode("h4", null, _toDisplayString(reportSheet.title), 1),
                                (!_ctx.user.sites_supervisor?.includes(reportSheet.site_id))
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('report_sheets.main_page.total')) + " : " + _toDisplayString(reportSheet.total_cost) + "€", 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['right'])
                              }, [
                                (reportSheet.status == 'pending')
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('report_sheets.main_page.filters.status.options.pending')), 1))
                                  : _createCommentVNode("", true),
                                (reportSheet.status == 'validated')
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                      _createElementVNode("p", {
                                        class: _normalizeClass(_ctx.$style['raport-validated'])
                                      }, _toDisplayString(_ctx.$t('report_sheets.main_page.filters.status.options.validated')), 3),
                                      _createElementVNode("p", null, _toDisplayString(_ctx.moment(reportSheet.validated_at).format("DD/MM/YYYY")), 1)
                                    ], 64))
                                  : _createCommentVNode("", true)
                              ], 2)
                            ], 2)
                          ]),
                          _: 2
                        }, 1032, ["sheet", "onClick"]))
                      }), 128)),
                      _createVNode(_component_ion_infinite_scroll, {
                        id: "infinite-scroll",
                        threshold: "500px",
                        onIonInfinite: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadNextPage($event)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_infinite_scroll_content)
                        ]),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_ion_text, {
                        class: "ion-text-center",
                        color: "medium"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('report_sheets.main_page.no_result')), 1)
                        ]),
                        _: 1
                      })
                    ]))
            ]),
            sidebar: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style['filters-content'])
              }, [
                (!_ctx.is_mobile)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass([_ctx.$style['filter-item-wrapper'], ""])
                    }, [
                      _createVNode(_component_ion_searchbar, {
                        modelValue: _ctx.search,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.search) = $event)),
                        class: _normalizeClass(_ctx.$style['filter-item-search']),
                        placeholder: _ctx.$t('report_sheets.main_page.filters.search_placeholder'),
                        color: "light",
                        mode: "ios"
                      }, null, 8, ["modelValue", "class", "placeholder"])
                    ], 2))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style['filter-item-wrapper'])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['filter-item'])
                  }, [
                    _createVNode(_component_ion_select, {
                      modelValue: _ctx.status,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.status) = $event)),
                      multiple: true,
                      placeholder: _ctx.$t('report_sheets.main_page.filters.status.label'),
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_select_option, { value: "pending" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.main_page.filters.status.options.pending')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_select_option, { value: "validated" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.main_page.filters.status.options.validated')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue", "placeholder"])
                  ], 2)
                ], 2)
              ], 2)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}