import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_EquipmentSkeleton = _resolveComponent("EquipmentSkeleton")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  (_ctx.loaded)
                    ? (_openBlock(), _createBlock(_component_ion_back_button, {
                        key: 0,
                        text: _ctx.$t('report_sheets.top_bar.back_to_steps'),
                        "default-href": `/report-sheets/${_ctx.report_sheet?.uuid}`
                      }, null, 8, ["text", "default-href"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.top_bar.title')) + " : " + _toDisplayString(_ctx.report_sheet?.title ?? _ctx.$t('report_sheets.top_bar.loading')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!_ctx.loaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_EquipmentSkeleton)
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style['annexes-wrapper'])
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.$t('report_sheets.annexes_page.validation.confirmation_title')), 1),
                  _createElementVNode("ul", {
                    class: _normalizeClass(_ctx.$style['buttons-wrapper'])
                  }, [
                    _createElementVNode("li", null, [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.presentActionSheet()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.annexes_page.validation.btn_confirm')), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ], 2)
                ])
              ], 2))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}