import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_EquipmentSkeleton = _resolveComponent("EquipmentSkeleton")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ContentSidebarWrapper = _resolveComponent("ContentSidebarWrapper")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    text: _ctx.$t('report_sheets.steps_page.back_button_label'),
                    "default-href": "/report-sheets"
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.top_bar.title')) + " : " + _toDisplayString(_ctx.sheet?.title ?? _ctx.$t('report_sheets.top_bar.loading')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ContentSidebarWrapper, { side: "left" }, {
            content: _withCtx(() => [
              (!_ctx.loaded)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_EquipmentSkeleton)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_ion_item, {
                      button: true,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(`/report-sheets/${_ctx.sheet?.uuid}/annexes/${_ctx.sheet?.annexes[0]?.uuid}`)))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass(_ctx.$style['inner'])
                        }, [
                          _createElementVNode("div", null, [
                            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.annexes.title')), 1)
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['right'])
                          }, [
                            (!_ctx.sheet?.annexes_step_validated)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.to_fill')), 1))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  (_ctx.sheet?.annexes_step_validated)
                                    ? (_openBlock(), _createElementBlock("p", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['step-validated'])
                                      }, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.validated')), 3))
                                    : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.to_validated')), 1))
                                ], 64))
                          ], 2)
                        ], 2)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, {
                      button: true,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push(`/report-sheets/${_ctx.sheet?.uuid}/equipments`)))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass(_ctx.$style['inner'])
                        }, [
                          _createElementVNode("div", null, [
                            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.materials.title')) + " (" + _toDisplayString(_ctx.sheet?.equipments.length) + ")", 1)
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['right'])
                          }, [
                            (_ctx.sheet?.equipments.length == 0 && !_ctx.sheet?.equipments_step_validated)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.to_fill')), 1))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  (_ctx.sheet?.equipments_step_validated)
                                    ? (_openBlock(), _createElementBlock("p", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['step-validated'])
                                      }, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.validated')), 3))
                                    : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.to_validated')), 1))
                                ], 64))
                          ], 2)
                        ], 2)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, {
                      button: true,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push(`/report-sheets/${_ctx.sheet?.uuid}/locations`)))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass(_ctx.$style['inner'])
                        }, [
                          _createElementVNode("div", null, [
                            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.locations.title')) + " (" + _toDisplayString(_ctx.sheet?.locations.length) + ")", 1)
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['right'])
                          }, [
                            (_ctx.sheet?.locations.length == 0 && !_ctx.sheet?.locations_step_validated)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.to_fill')), 1))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  (_ctx.sheet?.locations_step_validated)
                                    ? (_openBlock(), _createElementBlock("p", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['step-validated'])
                                      }, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.validated')), 3))
                                    : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.to_validated')), 1))
                                ], 64))
                          ], 2)
                        ], 2)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, {
                      button: true,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push(`/report-sheets/${_ctx.sheet?.uuid}/outsourced-works`)))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass(_ctx.$style['inner'])
                        }, [
                          _createElementVNode("div", null, [
                            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.outsourced_works.title')) + " (" + _toDisplayString(_ctx.sheet?.outsourced_works.length) + ")", 1)
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['right'])
                          }, [
                            (_ctx.sheet?.outsourced_works.length == 0 && !_ctx.sheet?.outsourced_work_step_validated)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.to_fill')), 1))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  (_ctx.sheet?.outsourced_work_step_validated)
                                    ? (_openBlock(), _createElementBlock("p", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['step-validated'])
                                      }, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.validated')), 3))
                                    : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.to_validated')), 1))
                                ], 64))
                          ], 2)
                        ], 2)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, {
                      button: true,
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push(`/report-sheets/${_ctx.sheet?.uuid}/employees`)))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass(_ctx.$style['inner'])
                        }, [
                          _createElementVNode("div", null, [
                            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.employees.title')) + " (" + _toDisplayString(_ctx.sheet?.employees.length) + ")", 1)
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['right'])
                          }, [
                            (_ctx.sheet?.employees.length == 0 && !_ctx.sheet?.employees_step_validated)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.to_fill')), 1))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  (_ctx.sheet?.employees_step_validated)
                                    ? (_openBlock(), _createElementBlock("p", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['step-validated'])
                                      }, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.validated')), 3))
                                    : (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.to_validated')), 1))
                                ], 64))
                          ], 2)
                        ], 2)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, {
                      button: true,
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push(`/report-sheets/${_ctx.sheet?.uuid}/summary`)))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass(_ctx.$style['inner'])
                        }, [
                          _createElementVNode("div", null, [
                            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('report_sheets.steps_page.steps.resume.title')), 1)
                          ])
                        ], 2)
                      ]),
                      _: 1
                    }),
                    (_ctx.canBeValidated)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (_ctx.sheet?.status != 'validated')
                            ? (_openBlock(), _createBlock(_component_ion_fab, {
                                key: 0,
                                slot: "fixed",
                                vertical: "bottom",
                                horizontal: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_fab_button, {
                                    color: "primary",
                                    onClick: _ctx.presentActionSheet
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, { icon: _ctx.caretUp }, null, 8, ["icon"])
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}