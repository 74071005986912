<template>
  <ion-page>
    <ion-split-pane
        :class="$style['split-pane']"
        content-id="main-content"
        when="lg"
    >
      <ion-menu
          :class="$style['ion-menu']"
          content-id="main-content"
          type="push"
      >
        <ion-content color="light">
          <div :class="$style['menu']">
            <div
                :class="{
                [$style['menu-logo']]: true,
                'ion-padding': true,
              }"
                @click="goTo($event, '')"
                v-html="user?.current_organization?.logo_svg"
            ></div>
            <div :class="$style['nav-links']" @click="closeMenu">
              <ion-button
                  :color="isActive('home') ? 'primary' : 'dark'"
                  buttonType="button"
                  expand="full"
                  fill="clear"
                  router-link="/home"
                  routerDirection="root"
              >
                <div :class="$style['nav-link']">
                  <ion-icon slot="start" :icon="dashboardSpeed"></ion-icon>
                  <span> {{ $t("menu_items.home") }}</span>
                </div>
              </ion-button>

              <ion-button
                  v-if="user.rights?.includes('orders.view')"
                  :color="isActive('orders') ? 'primary' : 'dark'"
                  buttonType="button"
                  expand="full"
                  fill="clear"
                  router-link="/orders"
                  routerDirection="root"
              >
                <div :class="$style['nav-link']">
                  <ion-icon slot="start" :icon="googleDocs"></ion-icon>
                  <span> {{ $t("menu_items.orders") }}</span>
                </div>
              </ion-button>

              <ion-button
                  v-if="user.rights?.includes('planning_entries.view')"
                  :color="isActive('planning') ? 'primary' : 'dark'"
                  buttonType="button"
                  expand="full"
                  fill="clear"
                  router-link="/planning"
                  routerDirection="root"
              >
                <div :class="$style['nav-link']">
                  <ion-icon slot="start" :icon="alarm"></ion-icon>
                  <span> {{ $t("menu_items.planning") }}</span>
                </div>
              </ion-button>

              <ion-button
                  v-if="
                  user.rights?.includes('usage_trackings.view') ||
                  user.rights?.includes('equipments.view')
                "
                  :color="isActive('equipment') ? 'primary' : 'dark'"
                  buttonType="button"
                  expand="full"
                  fill="clear"
                  router-link="/equipment"
                  routerDirection="root"
              >
                <div :class="$style['nav-link']">
                  <ion-icon slot="start" :icon="tools"></ion-icon>
                  <span> {{ $t("menu_items.equipment") }}</span>
                </div>
              </ion-button>

              <ion-button
                  v-if="user.rights?.includes('control_sheets.view')"
                  :color="isActive('control-sheets') ? 'primary' : 'dark'"
                  buttonType="button"
                  expand="full"
                  fill="clear"
                  router-link="/control-sheets"
                  routerDirection="root"
              >
                <div :class="$style['nav-link']">
                  <ion-icon slot="start" :icon="wrench"></ion-icon>
                  <span> {{ $t("menu_items.control_sheets") }}</span>
                </div>
              </ion-button>

              <ion-button
                  v-if="user.rights?.includes('report_sheets.view')"
                  :color="isActive('report-sheets') ? 'primary' : 'dark'"
                  buttonType="button"
                  expand="full"
                  fill="clear"
                  router-link="/report-sheets"
                  routerDirection="root"
              >
                <div :class="$style['nav-link']">
                  <ion-icon slot="start" :icon="statsReport"></ion-icon>
                  <span> {{ $t("menu_items.report_sheets") }}</span>
                </div>
              </ion-button>

              <!-- SCANNER -->

              <ion-button
                  v-if="is_mobile"
                  buttonType="button"
                  color="dark"
                  expand="full"
                  fill="clear"
                  @click="
                  $event.preventDefault();
                  $event.stopPropagation();
                  scanner_modal = true;
                "
              >
                <div :class="$style['nav-link']">
                  <ion-icon slot="start" :icon="scanQrCode"></ion-icon>
                  <span> {{ $t("menu_items.scanner") }}</span>
                </div>
              </ion-button>
            </div>

            <ion-button
                v-if="user.is_logged_in"
                :class="{
                [$style['user-item']]: true,
              }"
                color="medium"
                expand="full"
                fill="clear"
                @click="user_modal = true"
            >
              <div :class="$style['user-item-inner']">
                <ion-avatar
                    :class="{
                    [$style['menu-avatar']]: true,
                  }"
                >
                  <ion-img
                      v-if="user?.profile_picture"
                      :src="user.profile_picture"
                  />
                </ion-avatar>

                <p
                    :class="{
                    [$style['menu-username']]: true,
                  }"
                >
                  {{ user.display_name }}
                </p>
              </div>
            </ion-button>
          </div>
        </ion-content>
        <ion-modal
            :backdropDismiss="true"
            :canDismiss="true"
            :is-open="scanner_modal"
            @willDismiss="scanner_modal = false"
        >
          <ScannerPanel
              v-if="scanner_modal"
              :modalControls="true"
              @action="scannerAction"
              @close="scanner_modal = false"
              @skip="
              scanner_modal = false;
              equipment_tracker_modal = true;
            "
          />
        </ion-modal>
        <ion-modal
            :backdropDismiss="true"
            :canDismiss="true"
            :is-open="equipment_tracker_modal"
            @willDismiss="equipment_tracker_modal = false"
        >
          <ion-header>
            <ion-toolbar>
              <ion-buttons slot="end">
                <ion-button @click="equipment_tracker_modal = false">
                  {{ $t("global.close") }}
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <EquipmentTrackerPanel
              v-if="equipment_tracker_modal"
              :equipment="equipment_tracker_item"
              @close="equipment_tracker_modal = false"
          />
        </ion-modal>

        <ion-modal
            :backdropDismiss="true"
            :breakpoints="[0, 1]"
            :canDismiss="true"
            :class="$style['user-modal']"
            :initial-breakpoint="1"
            :is-open="user_modal"
            @willDismiss="user_modal = false"
        >
          <div :class="$style['user-menu']" class="ion-padding">
            <h3>{{ user?.display_name }}</h3>
          </div>

          <ion-list :class="$style['organizations-list']">
            <ion-item
                v-for="organization in user?.organizations"
                :key="organization.id"
                :class="{
                [$style['is-selected']]:
                  organization.id === user.current_organization.id,
              }"
                button="button"
                @click="switchToOrganization(organization.id)"
            >
              <ion-avatar slot="start">
                <ion-img
                    v-if="organization?.profile_picture"
                    :src="organization.profile_picture"
                />
              </ion-avatar>
              <ion-label>{{ organization.name }}</ion-label>
            </ion-item>
          </ion-list>

          <div class="ion-padding">
            <ion-button
                color="danger"
                expand="full"
                fill="clear"
                @click="logoutUser"
            >
              {{ $t("user.logout") }}
            </ion-button>
          </div>
        </ion-modal>
      </ion-menu>

      <ion-router-outlet id="main-content" :animated="is_mobile"/>
    </ion-split-pane>

    <ion-loading :isOpen="changing_organization"></ion-loading>

    <NotificationsBlock/>
  </ion-page>
</template>
<script lang="ts">
import {
  IonAvatar,
  IonButton,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenu,
  IonModal,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  menuController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useUserStore } from "@/stores/user";

import dashboardSpeed from "iconoir/icons/regular/dashboard-speed.svg";
import googleDocs from "iconoir/icons/regular/google-docs.svg";
import tools from "iconoir/icons/regular/tools.svg";
import wrench from "iconoir/icons/regular/wrench.svg";
import statsReport from "iconoir/icons/regular/stats-report.svg";
import alarm from "iconoir/icons/regular/alarm.svg";
import scanQrCode from "iconoir/icons/regular/scan-qr-code.svg";
import ScannerPanel from "@/components/data/scanner/ScannerPanel.vue";
import EquipmentTrackerPanel from "@/components/data/equipment-tracker/EquipmentTrackerPanel.vue";
import { Equipment } from "@/equipments";
import NotificationsBlock from "@/components/blocks/notifications/NotificationsBlock.vue";

export default defineComponent({
  components: {
    IonPage,
    NotificationsBlock,
    EquipmentTrackerPanel,
    ScannerPanel,
    IonIcon,
    IonList,
    IonItem,
    IonModal,
    IonButton,
    IonAvatar,
    IonImg,
    IonLabel,
    IonRouterOutlet,
    IonContent,
    IonLoading,
    IonSplitPane,
    IonMenu,
  },
  setup() {
    const is_mobile = window.innerWidth < 850;
    const user = useUserStore();

    return {
      is_mobile,
      user,
      menuController,

      dashboardSpeed: dashboardSpeed as string,
      googleDocs: googleDocs as string,
      tools: tools as string,
      alarm: alarm as string,
      scanQrCode: scanQrCode as string,
      wrench: wrench as string,
      statsReport: statsReport as string,
    };
  },
  data() {
    return {
      user_modal: false,
      presentingElement: undefined,
      changing_organization: false,
      scanner_modal: false,
      equipment_tracker_modal: false,
      equipment_tracker_item: null as Equipment | null,
      subscription: null as any,
    };
  },
  methods: {
    scannerAction(data: any) {
      if (data?.equipment) {
        this.equipment_tracker_item = data?.equipment;
        this.equipment_tracker_modal = true;
      }
    },
    closeMenu() {
      this.menuController.close();
    },
    goTo(e: MouseEvent, url: string) {
      e.preventDefault();
      this.$router.push({path: "/" + url});
      this.closeMenu();
    },
    isActive(path: string) {
      return this.$route.path.startsWith("/" + path);
    },
    switchToOrganization(organization_id: number) {
      this.changing_organization = true;
      this.axios
          .post("user/organization/switch", {organization_id})
          .then(() => {
            this.reloadApp(() => {
              this.changing_organization = false;
              window.location.reload();
            });
          });
    },
    logoutUser() {
      this.axios.post("user/logout").then(() => {
        this.reloadApp(() => {
          window.location.reload();
        });
      });
    },
    reloadApp(callback?: any) {
      (window as any)?.loadUser();
      this.$router.push({path: "/home"});
      this.user_modal = false;
      this.closeMenu();

      setTimeout(() => {
        if (callback) callback();
      }, 10);
    },
  },
});
</script>

<style lang="scss" module>
.menu-avatar {
  height: 2.5em;
  width: 2.5em;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--ion-color-primary);
    border-radius: 50%;
    opacity: 0.15;
  }
}

.menu-logo {
  min-height: 72px;
  flex-shrink: 0;

  svg {
    display: block;
    height: 2.5em;
    width: auto;
    fill: var(--ion-color-primary);
    color: var(--ion-color-primary);
  }

  @media screen and (min-width: 992px) {
    background: var(--ion-color-primary-shade);
    width: calc(100% + 1px);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: var(--ion-color-primary-contrast);
      color: var(--ion-color-primary-contrast);
    }
  }
}

.user-item {
  height: auto;
  flex-shrink: 0;

  .user-item-inner {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 0.75em;
    padding-bottom: 0.75em;

    @media screen and (min-width: 992px) {
      justify-content: center;
      flex-direction: column;

      > *:first-child {
        margin-right: 0;
        margin-bottom: 0.5em;
      }

      .menu-username {
        font-size: 0.8em;
        max-width: 100%;
      }
    }
  }

  .menu-username {
    font-size: 0.9em;
    flex-grow: 1;
    font-weight: bold;
    margin: 0;
    padding: 0;
    margin-right: 0.5em;
    margin-left: 0.5em;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  overflow: hidden;

  padding-bottom: var(
          --ion-safe-area-bottom,
          env(safe-area-inset-bottom, 0)
  ) !important;
  padding-top: var(--ion-safe-area-top, env(safe-area-inset-top, 0)) !important;
}

.user-menu {
  display: flex;
}

.organizations-list {
  flex-grow: 1;
}

.user-menu-wrapper {
  height: calc(100% - 5.5em);
  display: flex;
  flex-direction: column;
}

.is-selected {
  font-weight: bold;
}

.nav-links {
  flex-grow: 1;

  padding-top: 2em;

  height: calc(100% - 12em);
  flex-shrink: 1;
  overflow: auto;
  overflow-x: hidden;

  @media screen and (min-width: 992px) {
    padding-top: 0;
  }

  * {
    text-align: left;
  }

  ion-button {
    height: auto;
  }

  .nav-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-top: 0em;
    padding-bottom: 0em;

    > ion-icon {
      margin-right: 0.5em;
      font-size: 1.25em;
    }

    @media screen and (min-width: 992px) {
      justify-content: center;
      flex-direction: column;
      text-transform: uppercase;
      font-weight: bold;
      padding-top: 1.5em;
      padding-bottom: 1.5em;

      > ion-icon {
        margin-right: 0;
        margin-bottom: 0.5em;
        font-size: 1.75em;
      }
      > span {
        font-size: 0.7em;
      }
    }
  }
}

.split-pane {
  --side-width: 120px !important;
  --side-min-width: 120px !important;
  --side-max-width: 120px !important;
}

ion-modal.user-modal {
  --height: auto;
}

.ion-menu {
  border-right: none;
}
</style>
