<template>
  <ion-item :button="true" @click="$router.push('/orders/' + order.uuid)">
    <div :class="$style['inner']">
      <div>
        <h4>{{ order.display_name }}</h4>
        <p v-if="0&&path">{{ path }}</p>
        <p v-if=" order.provider?.name">{{ order.provider?.name }}</p>

      </div>
      <div :class="$style['right']">
        <OrderStatus :order="order"/>
        <p v-if="!order?.sync_needed" :class="$style['date']">
          {{
            moment(order.created_at) > moment().subtract(14, 'days') ? moment(order.created_at).fromNow() : moment(order.created_at).format('LL')
          }}
        </p>

        <div :class="$style['user-pictures']">
          <img v-for="role in ordered_roles" :key="role.user.uuid" :src="role.user.profile_picture" loading="lazy"/>
        </div>
      </div>
    </div>
  </ion-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { UserRole } from '@/users';

import { useUserStore } from "@/stores/user";

import { IonItem } from "@ionic/vue";
import OrderStatus from "@/components/data/orders/OrderStatus.vue";

import moment from "moment";

export default defineComponent({
  props: ["order"],
  components: {
    IonItem,
    OrderStatus
  },
  setup() {
    const user = useUserStore();
    return {user, moment};
  },
  computed: {
    path(): string {
      return this.order.folder && this.order.folder?.length ? this.order.folder.split('/').map((folder: string) => this.$t('orders.folder_labels.' + folder)).join(' / ') : null
    },
    ordered_roles(): UserRole[] {
      const unique_roles: UserRole[] = [];

      this.$props.order?.roles?.forEach((role: UserRole) => {
        if (!unique_roles.find(r => r.user.uuid === role.user.uuid)) {
          unique_roles.push(role);
        }
      });

      this.$props.order?.site?.roles?.forEach((role: UserRole) => {
        if (!unique_roles.find(r => r.user.uuid === role.user.uuid)) {
          unique_roles.push(role);
        }
      });

      return unique_roles?.sort((a: UserRole, b: UserRole) => {
        if (a.role === 'issuer') {
          return -1;
        }
        if (b.role === 'issuer') {
          return 1;
        }
        if (a.role === 'validator') {
          return 1;
        }
        if (b.role === 'validator') {
          return -1;
        }
        return 0;
      });
    }
  }
});
</script>

<style lang="scss" module>
.inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
  font-size: .9em;

  @media(max-width: 990px) {
    font-size: .8em;
  }

  @media(max-width: 700px) {
    flex-direction: column
  }

  h4, p {
    padding: 0;
    margin: 0;
  }

  p {
    margin-top: .25em;
    color: var(--ion-color-medium);
  }

  .right {
    display: flex;
    align-items: center;

    @media(min-width: 701px) {
      flex-direction: column;
      align-items: flex-end;
    }

    @media(max-width: 700px) {
      .date {
        &:before {
          content: '—';
          margin: 0 .5em;
          margin-top: 0;
        }
      }
    }
  }
}

.user-pictures {
  @media(max-width: 700px) {
    display: none;
  }

  $size: 2em;

  margin-top: 1em;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  justify-content: flex-end;

  margin-left: $size*.2;

  img {
    width: $size;
    height: $size;
    border-radius: 50%;
    margin-left: -$size*.25;
    object-fit: cover;
    border: $size*.05 solid var(--background);
  }
}
</style>