import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_EquipmentSkeleton = _resolveComponent("EquipmentSkeleton")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  (_ctx.loaded)
                    ? (_openBlock(), _createBlock(_component_ion_back_button, {
                        key: 0,
                        text: _ctx.$t('report_sheets.top_bar.back_to_steps'),
                        "default-href": `/report-sheets/${_ctx.sheet?.uuid}`
                      }, null, 8, ["text", "default-href"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.top_bar.title')) + " : " + _toDisplayString(_ctx.sheet?.title ?? _ctx.$t('report_sheets.top_bar.loading')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!_ctx.loaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_EquipmentSkeleton)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style['container-table'])
                }, [
                  _createVNode(_component_ion_grid, {
                    classs: _ctx.$style['title']
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, {
                        class: _normalizeClass(_ctx.$style['title'])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.employees.title')) + " (" + _toDisplayString(_ctx.sheet?.employees.length ?? 0) + ")", 1)
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  }, 8, ["classs"]),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['table-wrapper'])
                  }, [
                    _createElementVNode("table", {
                      class: _normalizeClass(_ctx.$style['data-table'])
                    }, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.employees.col_employee_number')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.employees.col_name')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.employees.col_activity')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.employees.col_comment')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.employees.col_travel')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.employees.col_mobility')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.employees.col_start_at')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.employees.col_end_at')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.employees.col_total_rest')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.employees.col_total')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.employees.col_mobility_code')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.employees.col_other_service')), 1)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sheet?.employees, (employee) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: employee.uuid
                          }, [
                            _createElementVNode("td", null, _toDisplayString(employee.reference), 1),
                            _createElementVNode("td", null, _toDisplayString(employee.firstname) + " " + _toDisplayString(employee.lastname), 1),
                            _createElementVNode("td", null, _toDisplayString(employee.activity), 1),
                            _createElementVNode("td", null, _toDisplayString(employee.comment), 1),
                            _createElementVNode("td", null, _toDisplayString(employee.travel), 1),
                            _createElementVNode("td", null, _toDisplayString(employee.mobility), 1),
                            _createElementVNode("td", null, _toDisplayString(employee.start_at), 1),
                            _createElementVNode("td", null, _toDisplayString(employee.end_at), 1),
                            _createElementVNode("td", null, _toDisplayString(employee.rest_time), 1),
                            _createElementVNode("td", null, _toDisplayString(employee.total_hours), 1),
                            _createElementVNode("td", {
                              class: _normalizeClass(_ctx.$style['truncate'])
                            }, _toDisplayString(employee.mobility_code), 3),
                            _createElementVNode("td", null, _toDisplayString(employee.services_other), 1)
                          ]))
                        }), 128))
                      ])
                    ], 2)
                  ], 2)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style['container-table'])
                }, [
                  _createVNode(_component_ion_grid, {
                    classs: _ctx.$style['title']
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, {
                        class: _normalizeClass(_ctx.$style['title'])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.materials.title')) + " (" + _toDisplayString(_ctx.sheet?.equipments.length ?? 0) + ")", 1)
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  }, 8, ["classs"]),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['table-wrapper'])
                  }, [
                    _createElementVNode("table", {
                      class: _normalizeClass(_ctx.$style['data-table'])
                    }, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.materials.col_title')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.materials.col_name')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.materials.col_unity')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.materials.col_quantity')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.materials.col_employee')), 1)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sheet?.equipments, (equipment) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: equipment.uuid
                          }, [
                            _createElementVNode("td", null, _toDisplayString(equipment.reference), 1),
                            _createElementVNode("td", null, _toDisplayString(equipment.name), 1),
                            _createElementVNode("td", null, _toDisplayString(equipment.unity), 1),
                            _createElementVNode("td", null, _toDisplayString(equipment.quantity), 1),
                            _createElementVNode("td", null, _toDisplayString(equipment.employee_firstname) + " " + _toDisplayString(equipment.employee_lastname), 1)
                          ]))
                        }), 128))
                      ])
                    ], 2)
                  ], 2)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style['container-table'])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['table-wrapper'])
                  }, [
                    _createVNode(_component_ion_grid, {
                      classs: _ctx.$style['title']
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_row, {
                          class: _normalizeClass(_ctx.$style['title'])
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.locations.title')) + " (" + _toDisplayString(_ctx.sheet?.locations.length ?? 0) + ")", 1)
                          ]),
                          _: 1
                        }, 8, ["class"])
                      ]),
                      _: 1
                    }, 8, ["classs"]),
                    _createElementVNode("table", {
                      class: _normalizeClass(_ctx.$style['data-table'])
                    }, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.locations.col_title')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.locations.col_unity')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.locations.col_quantity')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.locations.col_employee')), 1)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sheet?.locations, (location) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: location.uuid
                          }, [
                            _createElementVNode("td", null, _toDisplayString(location.name), 1),
                            _createElementVNode("td", null, _toDisplayString(location.unity), 1),
                            _createElementVNode("td", null, _toDisplayString(location.quantity), 1),
                            _createElementVNode("td", null, _toDisplayString(location.employee_firstname) + " " + _toDisplayString(location.employee_lastname), 1)
                          ]))
                        }), 128))
                      ])
                    ], 2)
                  ], 2)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style['container-table'])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['table-wrapper'])
                  }, [
                    _createVNode(_component_ion_grid, {
                      classs: _ctx.$style['title']
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_row, {
                          class: _normalizeClass(_ctx.$style['title'])
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.outsourced_works.title')) + " (" + _toDisplayString(_ctx.sheet?.outsourced_works.length ?? 0) + ")", 1)
                          ]),
                          _: 1
                        }, 8, ["class"])
                      ]),
                      _: 1
                    }, 8, ["classs"]),
                    _createElementVNode("table", {
                      class: _normalizeClass(_ctx.$style['data-table'])
                    }, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.outsourced_works.col_title')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.outsourced_works.col_unity')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('report_sheets.page_resume.data_table.outsourced_works.col_quantity')), 1)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sheet?.outsourced_works, (outsourced_work) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: outsourced_work.uuid
                          }, [
                            _createElementVNode("td", null, _toDisplayString(outsourced_work.name), 1),
                            _createElementVNode("td", null, _toDisplayString(outsourced_work.unity), 1),
                            _createElementVNode("td", null, _toDisplayString(outsourced_work.quantity), 1)
                          ]))
                        }), 128))
                      ])
                    ], 2)
                  ], 2)
                ], 2)
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}