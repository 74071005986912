import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_UserSkeleton = _resolveComponent("UserSkeleton")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("user.screen_title")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.user.is_logged_in)
        ? (_openBlock(), _createBlock(_component_ion_content, {
            key: 0,
            class: _normalizeClass([_ctx.$style['wrapper'], "ion-padding"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_grid, { fixed: true }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        size: "12",
                        "size-lg": "4",
                        "size-md": "12",
                        "size-sm": "12"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("user.informations.title")), 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.informations_fields, (field) => {
                            return (_openBlock(), _createBlock(_component_ion_item, { key: field }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("user.informations." + field)), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_text, { color: "medium" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.user.getValue(field)), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, {
                        "offset-lg": "2",
                        size: "12",
                        "size-lg": "4",
                        "size-md": "12",
                        "size-sm": "12"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("user.credentials.title")), 1),
                          _createElementVNode("form", {
                            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changePassword && _ctx.changePassword(...args)), ["prevent"]))
                          }, [
                            (_ctx.user.has_password)
                              ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t("user.credentials.current_password")), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_input, {
                                      slot: "end",
                                      modelValue: _ctx.current_password,
                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.current_password) = $event)),
                                      type: "password"
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_input, {
                                  name: "new_password",
                                  slot: "end",
                                  modelValue: _ctx.new_password,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.new_password) = $event)),
                                  type: "password",
                                  label: _ctx.$t('user.credentials.new_password')
                                }, null, 8, ["modelValue", "label"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_input, {
                                  name: "new_password_verif",
                                  slot: "end",
                                  modelValue: _ctx.new_password_verif,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.new_password_verif) = $event)),
                                  type: "password",
                                  label: _ctx.$t('user.credentials.new_password_verif')
                                }, null, 8, ["modelValue", "label"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_button, {
                              expand: "full",
                              type: "submit",
                              onClick: _withModifiers(_ctx.changePassword, ["prevent"])
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("user.credentials.change_password_submit")), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ], 32)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]))
        : (_openBlock(), _createBlock(_component_ion_content, {
            key: 1,
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_UserSkeleton)
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}