import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_EquipmentSkeleton = _resolveComponent("EquipmentSkeleton")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_uploadable_file = _resolveComponent("uploadable-file")!
  const _component_files_preview = _resolveComponent("files-preview")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  (_ctx.loaded)
                    ? (_openBlock(), _createBlock(_component_ion_back_button, {
                        key: 0,
                        text: _ctx.$t('report_sheets.top_bar.back_to_steps'),
                        "default-href": `/report-sheets/${_ctx.annex?.report_sheet?.uuid}`
                      }, null, 8, ["text", "default-href"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.top_bar.title')) + " : " + _toDisplayString(_ctx.annex?.report_sheet?.title ?? _ctx.$t('report_sheets.top_bar.loading')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.loaded)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style['sub-header'])
          }, _toDisplayString(_ctx.$t('report_sheets.annexes_page.sub_top_bar_title')) + " " + _toDisplayString(_ctx.annex?.order) + "/" + _toDisplayString(_ctx.totalAnnex), 3))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!_ctx.loaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_EquipmentSkeleton)
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style['annexes-wrapper'])
              }, [
                (!_ctx.showAnnexData)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("h1", null, _toDisplayString(_ctx.annex?.title), 1),
                      _createElementVNode("ul", {
                        class: _normalizeClass(_ctx.$style['buttons-wrapper'])
                      }, [
                        _createElementVNode("li", null, [
                          _createVNode(_component_ion_button, {
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAnnexData = true))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.annexes_page.answer_yes')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("li", null, [
                          _createVNode(_component_ion_button, {
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToNextAnnex()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.annexes_page.answer_no')), 1)
                            ]),
                            _: 1
                          })
                        ])
                      ], 2)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.showAnnexData)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("h1", {
                        class: _normalizeClass(_ctx.$style['more-data-subtitle'])
                      }, _toDisplayString(_ctx.annex?.sub_title), 3),
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['space-wrapper'])
                      }, [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.annexes_page.form.comment_label')) + " :", 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_textarea, {
                          disabled: _ctx.annex?.report_sheet?.annexes_step_validated,
                          class: _normalizeClass(_ctx.$style['annexes-textarea']),
                          "auto-grow": true,
                          modelValue: _ctx.annex.comment,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.annex.comment) = $event))
                        }, null, 8, ["disabled", "class", "modelValue"])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['space-wrapper'])
                      }, [
                        (!_ctx.annex?.report_sheet?.annexes_step_validated)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.annexes_page.form.files_label')) + " :", 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_uploadable_file, {
                                value: [],
                                accept: "image/*",
                                capture: "environment",
                                multiple: "true",
                                onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.files = [..._ctx.files, ...$event]))
                              })
                            ], 64))
                          : _createCommentVNode("", true)
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['space-wrapper'])
                      }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.files.length) + " " + _toDisplayString(_ctx.$t('report_sheets.annexes_page.form.total_files')), 1),
                        (_ctx.files)
                          ? (_openBlock(), _createBlock(_component_files_preview, {
                              key: 0,
                              files: _ctx.files
                            }, null, 8, ["files"]))
                          : _createCommentVNode("", true)
                      ], 2),
                      _createElementVNode("ul", {
                        class: _normalizeClass(_ctx.$style['buttons-wrapper'])
                      }, [
                        (!_ctx.annex?.report_sheet?.annexes_step_validated)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                              _createVNode(_component_ion_button, {
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showAnnexData = false))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.annexes_page.form.btn_cancel')), 1)
                                ]),
                                _: 1
                              })
                            ]))
                          : _createCommentVNode("", true),
                        (!_ctx.annex?.report_sheet?.annexes_step_validated)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                              _createVNode(_component_ion_button, {
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.saveAnnex()))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('report_sheets.annexes_page.form.btn_validate')), 1)
                                ]),
                                _: 1
                              })
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.annex?.report_sheet?.annexes_step_validated)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                              _createVNode(_component_ion_button, {
                                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goToNextAnnex()))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.annex?.order != _ctx.totalAnnex ? _ctx.$t('report_sheets.annexes_page.form.btn_next') : _ctx.$t('report_sheets.annexes_page.form.btn_go_to_steps_page')), 1)
                                ]),
                                _: 1
                              })
                            ]))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}