<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-back-button :text="$t('report_sheets.steps_page.back_button_label')" default-href="/report-sheets"></ion-back-button>
                </ion-buttons>
                <ion-title>{{ $t('report_sheets.top_bar.title') }} : {{ sheet?.title ?? $t('report_sheets.top_bar.loading') }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <ContentSidebarWrapper side="left">
                <template v-slot:content>
                    <div v-if="!loaded">
                        <EquipmentSkeleton/>
                    </div>
                    <div v-else>
                        <ion-item :button="true" @click="$router.push(`/report-sheets/${sheet?.uuid}/annexes/${sheet?.annexes[0]?.uuid}`)">
                            <div :class="$style['inner']">
                                <div>
                                    <h4>{{ $t('report_sheets.steps_page.steps.annexes.title') }}</h4>
                                </div>

                                <div :class="$style['right']">
                                    <p v-if="!sheet?.annexes_step_validated">{{ $t('report_sheets.steps_page.steps.to_fill') }}</p>

                                    <template v-else>
                                        <p v-if="sheet?.annexes_step_validated" :class="$style['step-validated']">{{ $t('report_sheets.steps_page.steps.validated') }}</p>
                                        <p v-else>{{ $t('report_sheets.steps_page.steps.to_validated') }}</p>
                                    </template>
                                </div>
                            </div>
                        </ion-item>

                        <ion-item :button="true" @click="$router.push(`/report-sheets/${sheet?.uuid}/equipments`)">
                            <div :class="$style['inner']">
                                <div>
                                    <h4>{{ $t('report_sheets.steps_page.steps.materials.title') }} ({{ sheet?.equipments.length }})</h4>
                                </div>

                                <div :class="$style['right']">
                                    <p v-if="sheet?.equipments.length == 0 && !sheet?.equipments_step_validated">{{ $t('report_sheets.steps_page.steps.to_fill') }}</p>

                                    <template v-else>
                                        <p v-if="sheet?.equipments_step_validated" :class="$style['step-validated']">{{ $t('report_sheets.steps_page.steps.validated') }}</p>
                                        <p v-else>{{ $t('report_sheets.steps_page.steps.to_validated') }}</p>
                                    </template>
                                </div>
                            </div>
                        </ion-item>

                        <ion-item :button="true" @click="$router.push(`/report-sheets/${sheet?.uuid}/locations`)">
                            <div :class="$style['inner']">
                                <div>
                                    <h4>{{ $t('report_sheets.steps_page.steps.locations.title') }} ({{ sheet?.locations.length }})</h4>
                                </div>

                                <div :class="$style['right']">
                                    <p v-if="sheet?.locations.length == 0 && !sheet?.locations_step_validated">{{ $t('report_sheets.steps_page.steps.to_fill') }}</p>

                                    <template v-else>
                                        <p v-if="sheet?.locations_step_validated" :class="$style['step-validated']">{{ $t('report_sheets.steps_page.steps.validated') }}</p>
                                        <p v-else>{{ $t('report_sheets.steps_page.steps.to_validated') }}</p>
                                    </template>
                                </div>
                            </div>
                        </ion-item>

                        <ion-item :button="true" @click="$router.push(`/report-sheets/${sheet?.uuid}/outsourced-works`)">
                            <div :class="$style['inner']">
                                <div>
                                    <h4>{{ $t('report_sheets.steps_page.steps.outsourced_works.title') }} ({{ sheet?.outsourced_works.length }})</h4>
                                </div>

                                <div :class="$style['right']">
                                    <p v-if="sheet?.outsourced_works.length == 0 && !sheet?.outsourced_work_step_validated">{{ $t('report_sheets.steps_page.steps.to_fill') }}</p>

                                    <template v-else>
                                        <p v-if="sheet?.outsourced_work_step_validated" :class="$style['step-validated']">{{ $t('report_sheets.steps_page.steps.validated') }}</p>
                                        <p v-else>{{ $t('report_sheets.steps_page.steps.to_validated') }}</p>
                                    </template>
                                </div>
                            </div>
                        </ion-item>

                        <ion-item :button="true" @click="$router.push(`/report-sheets/${sheet?.uuid}/employees`)">
                            <div :class="$style['inner']">
                                <div>
                                    <h4>{{ $t('report_sheets.steps_page.steps.employees.title') }} ({{ sheet?.employees.length }})</h4>
                                </div>

                                <div :class="$style['right']">
                                    <p v-if="sheet?.employees.length == 0 && !sheet?.employees_step_validated">{{ $t('report_sheets.steps_page.steps.to_fill') }}</p>
                                    
                                    <template v-else>
                                        <p v-if="sheet?.employees_step_validated" :class="$style['step-validated']">{{ $t('report_sheets.steps_page.steps.validated') }}</p>
                                        <p v-else>{{ $t('report_sheets.steps_page.steps.to_validated') }}</p>
                                    </template>
                                </div>
                            </div>
                        </ion-item>

                        <ion-item :button="true" @click="$router.push(`/report-sheets/${sheet?.uuid}/summary`)">
                            <div :class="$style['inner']">
                                <div>
                                    <h4>{{ $t('report_sheets.steps_page.steps.resume.title') }}</h4>
                                </div>
                            </div>
                        </ion-item>

                        <template v-if="canBeValidated">
                            <ion-fab v-if="sheet?.status != 'validated'" slot="fixed" vertical="bottom" horizontal="end">
                                <ion-fab-button color="primary" @click="presentActionSheet">
                                    <ion-icon :icon="caretUp"></ion-icon>
                                </ion-fab-button>
                            </ion-fab>
                        </template>
                    </div>
                </template>
            </ContentSidebarWrapper>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import {
        IonBackButton,
        IonButtons,
        IonContent,
        IonHeader,
        IonPage,
        IonToolbar,
        toastController,
        IonFab,
        IonFabButton,
        actionSheetController,
        alertController,
        IonIcon
    } from "@ionic/vue"

    import {
        caretUp
    } from 'ionicons/icons'

    import { defineComponent } from "vue"
    import ContentSidebarWrapper from "@/components/wrappers/ContentSidebarWrapper.vue"
    import { ReportSheet } from "@/report-sheets"
    import EquipmentSkeleton from "@/components/skeletons/EquipmentSkeleton.vue"

    export default defineComponent({
        components: {
            ContentSidebarWrapper,
            IonBackButton,
            IonButtons,
            IonContent,
            IonHeader,
            IonPage,
            EquipmentSkeleton,
            IonToolbar,
            IonFab,
            IonFabButton,
            IonIcon
        },
        setup() {
            return { caretUp }
        },
        data() {
            return {
                sheet: null as ReportSheet | null,
                loaded: false as boolean | false,
                canBeValidated: false
            };
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.axios.get("/report-sheets/" + this.$route.params.uuidReport)
                .then((response) => {
                    this.sheet = response.data

                    if (this.sheet?.annexes_step_validated && this.sheet?.employees_step_validated && this.sheet?.equipments_step_validated && this.sheet?.locations_step_validated && this.sheet?.outsourced_work_step_validated)
                        this.canBeValidated = true
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loaded = true
                })
            },
            async presentActionSheet() {
                const actionSheet = await actionSheetController.create({
                    buttons: [
                        {
                            text: this.$t('report_sheets.steps_page.alert.title'),
                            handler: async () => {
                                const alert = await alertController.create({
                                    message: this.$t('report_sheets.steps_page.alert.message'),
                                    htmlAttributes: {
                                        'aria-label': 'alert dialog'
                                    },
                                    buttons: [
                                        {
                                            text: this.$t('report_sheets.steps_page.alert.buttons.cancel'),
                                            role: "cancel"
                                        },
                                        {
                                            text: this.$t('report_sheets.steps_page.alert.buttons.confirm'),
                                            role: "confirm",
                                            handler: () => {
                                                this.reportValidation()
                                            }
                                        }
                                    ]
                                })

                                await alert.present()
                            }
                        },
                        {
                            text: this.$t('report_sheets.steps_page.alert.buttons.cancel'),
                            role: "cancel"
                        }
                    ]
                })

                await actionSheet.present()
            },
            reportValidation() {
                this.loaded = false

                this.axios.patch(`/report-sheets/${this.$route.params.uuidReport}/validation`)
                .then(async () => {
                    (
                        await toastController.create({
                            header: this.$t('report_sheets.steps_page.validation.title'),
                            message: this.$t('report_sheets.steps_page.validation.message'),
                            duration: 5000,
                            color: "success"
                        })
                    ).present()
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loaded = true
                })
            }
        }
    })
</script>

<style lang="scss" module>
    .inner {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1em;
        font-size: .9em;

        @media(max-width: 990px) {
            font-size: .8em;
        }
        @media(max-width: 700px) {
            flex-direction: column
        }
        h4, p {
            padding: 0;
            margin: 0;
        }
        p {
            margin-top: .25em;
            color: var(--ion-color-medium);
        }
        .right {
            display: flex;
            align-items: center;

            @media(min-width: 701px) {
                flex-direction: column;
                align-items: flex-end;
            }

            @media(max-width: 700px) {
                .date {
                    &:before {
                        content: '—';
                        margin: 0 .5em;
                        margin-top: 0;
                    }
                }
            }
        }
    }
    .step-validated {
        color: var(--ion-color-success) !important;
        font-weight: bold;
    }
</style>