<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-back-button v-if="loaded" :text="$t('report_sheets.top_bar.back_to_steps')" :default-href="`/report-sheets/${sheet?.uuid}`"></ion-back-button>
                </ion-buttons>
                <ion-title>{{ $t('report_sheets.top_bar.title') }} : {{ sheet?.title ?? $t('report_sheets.top_bar.loading') }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <div v-if="!loaded">
                <EquipmentSkeleton/>
            </div>
            <div v-else>
                <div :class="$style['container-table']">
                    <ion-grid :classs="$style['title']">
                        <ion-row :class="$style['title']">{{ $t('report_sheets.page_resume.data_table.employees.title') }} ({{ sheet?.employees.length ?? 0 }})</ion-row>
                    </ion-grid>

                    <div :class="$style['table-wrapper']">
                        <table :class="$style['data-table']">
                            <thead>
                                <tr>
                                    <th>{{ $t('report_sheets.page_resume.data_table.employees.col_employee_number') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.employees.col_name') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.employees.col_activity') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.employees.col_comment') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.employees.col_travel') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.employees.col_mobility') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.employees.col_start_at') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.employees.col_end_at') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.employees.col_total_rest') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.employees.col_total') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.employees.col_mobility_code') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.employees.col_other_service') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="employee in sheet?.employees" :key="employee.uuid">
                                    <td>{{ employee.reference }}</td>
                                    <td>{{ employee.firstname }} {{ employee.lastname }}</td>
                                    <td>{{ employee.activity }}</td>
                                    <td>{{ employee.comment }}</td>
                                    <td>{{ employee.travel }}</td>
                                    <td>{{ employee.mobility }}</td>
                                    <td>{{ employee.start_at }}</td>
                                    <td>{{ employee.end_at }}</td>
                                    <td>{{ employee.rest_time }}</td>
                                    <td>{{ employee.total_hours }}</td>
                                    <td :class="$style['truncate']">{{ employee.mobility_code }}</td>
                                    <td>{{ employee.services_other }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div :class="$style['container-table']">
                    <ion-grid :classs="$style['title']">
                        <ion-row :class="$style['title']">{{ $t('report_sheets.page_resume.data_table.materials.title') }} ({{ sheet?.equipments.length ?? 0 }})</ion-row>
                    </ion-grid>

                    <div :class="$style['table-wrapper']">
                        <table :class="$style['data-table']">
                            <thead>
                                <tr>
                                    <th>{{ $t('report_sheets.page_resume.data_table.materials.col_title') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.materials.col_name') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.materials.col_unity') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.materials.col_quantity') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.materials.col_employee') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="equipment in sheet?.equipments" :key="equipment.uuid">
                                    <td>{{ equipment.reference }}</td>
                                    <td>{{ equipment.name }}</td>
                                    <td>{{ equipment.unity }}</td>
                                    <td>{{ equipment.quantity }}</td>
                                    <td>{{ equipment.employee_firstname }} {{ equipment.employee_lastname }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div :class="$style['container-table']">
                    <div :class="$style['table-wrapper']">
                        <ion-grid :classs="$style['title']">
                            <ion-row :class="$style['title']">{{ $t('report_sheets.page_resume.data_table.locations.title') }} ({{ sheet?.locations.length ?? 0 }})</ion-row>
                        </ion-grid>

                        <table :class="$style['data-table']">
                            <thead>
                                <tr>
                                    <th>{{ $t('report_sheets.page_resume.data_table.locations.col_title') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.locations.col_unity') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.locations.col_quantity') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.locations.col_employee') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="location in sheet?.locations" :key="location.uuid">
                                    <td>{{ location.name }}</td>
                                    <td>{{ location.unity }}</td>
                                    <td>{{ location.quantity }}</td>
                                    <td>{{ location.employee_firstname }} {{ location.employee_lastname }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div :class="$style['container-table']">
                    <div :class="$style['table-wrapper']">
                        <ion-grid :classs="$style['title']">
                            <ion-row :class="$style['title']">{{ $t('report_sheets.page_resume.data_table.outsourced_works.title') }} ({{ sheet?.outsourced_works.length ?? 0 }})</ion-row>
                        </ion-grid>

                        <table :class="$style['data-table']">
                            <thead>
                                <tr>
                                    <th>{{ $t('report_sheets.page_resume.data_table.outsourced_works.col_title') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.outsourced_works.col_unity') }}</th>
                                    <th>{{ $t('report_sheets.page_resume.data_table.outsourced_works.col_quantity') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="outsourced_work in sheet?.outsourced_works" :key="outsourced_work.uuid">
                                    <td>{{ outsourced_work.name }}</td>
                                    <td>{{ outsourced_work.unity }}</td>
                                    <td>{{ outsourced_work.quantity }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import {
        IonBackButton,
        IonButtons,
        IonContent,
        IonHeader,
        IonPage,
        IonToolbar,
        IonGrid,
        IonRow
    } from "@ionic/vue"

    import { defineComponent } from "vue"
    import { ReportSheet } from "@/report-sheets"
    import EquipmentSkeleton from "@/components/skeletons/EquipmentSkeleton.vue"

    export default defineComponent({
        components: {
            IonBackButton,
            IonButtons,
            IonContent,
            IonHeader,
            IonPage,
            EquipmentSkeleton,
            IonToolbar,
            IonGrid,
            IonRow
        },
        data() {
            return {
                sheet: null as ReportSheet | null,
                loaded: false as boolean | false
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.axios.get("/report-sheets/" + this.$route.params.uuidReport)
                .then((response) => {
                    this.sheet = response.data
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loaded = true
                })
            }
        }
    })
</script>

<style lang="scss" module>
    ion-grid {
        padding: 0;
    }
    ion-row.title {
        background: #000;
        color: #fff;
        display: flex;
        justify-content: center;
    }
    ion-row.header {
        background: #9e9e9e;
    }
    ion-row.data:nth-child(even) {
        background: #ededed;
    }
    .container-table {
        margin: 20px;
    }
    .table-wrapper {
        overflow-x: auto;
        white-space: nowrap;

        .title {
            background: #000;
            color: #fff;
            display: flex;
            justify-content: center;
        }
    }
    .data-table {
        border-collapse: collapse;
        width: 100%;
        white-space: nowrap;
    }
    .data-table td, .data-table th {
        border: 1px solid #ddd;
        padding: 8px;
    }
    // .truncate {
        // text-overflow: ellipsis;
        // overflow: hidden;
        // max-width: 400px;
    // }
    .data-table tr:nth-child(even) {
        background-color: #f2f2f2;
    }
    .data-table tr:hover {
        background-color: #ddd;
    }
    .data-table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #c9c9c9;
    }
</style>