<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-back-button v-if="loaded" :text="$t('report_sheets.top_bar.back_to_steps')" :default-href="`/report-sheets/${annex?.report_sheet?.uuid}`"></ion-back-button>
                </ion-buttons>

                <ion-title>{{ $t('report_sheets.top_bar.title') }} : {{ annex?.report_sheet?.title ?? $t('report_sheets.top_bar.loading') }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <div v-if="loaded" :class="$style['sub-header']">{{ $t('report_sheets.annexes_page.sub_top_bar_title') }} {{ annex?.order }}/{{ totalAnnex }}</div>

        <ion-content>
            <div v-if="!loaded">
                <EquipmentSkeleton/>
            </div>
            <template v-else>
                <div :class="$style['annexes-wrapper']">
                    <div v-if="!showAnnexData">
                        <h1>{{ annex?.title }}</h1>

                        <ul :class="$style['buttons-wrapper']">
                            <li><ion-button @click="showAnnexData = true">{{ $t('report_sheets.annexes_page.answer_yes') }}</ion-button></li>
                            <li><ion-button @click="goToNextAnnex()">{{ $t('report_sheets.annexes_page.answer_no') }}</ion-button></li>
                        </ul>
                    </div>

                    <div v-if="showAnnexData">
                        <h1 :class="$style['more-data-subtitle']">{{ annex?.sub_title }}</h1>

                        <div :class="$style['space-wrapper']">
                            <ion-label>{{ $t('report_sheets.annexes_page.form.comment_label') }} :</ion-label>
                            <ion-textarea :disabled="annex?.report_sheet?.annexes_step_validated" :class="$style['annexes-textarea']" :auto-grow="true" v-model="annex.comment"></ion-textarea>
                        </div>

                        <div :class="$style['space-wrapper']">
                            <template v-if="!annex?.report_sheet?.annexes_step_validated">
                                <ion-label>{{ $t('report_sheets.annexes_page.form.files_label') }} :</ion-label>
                                <uploadable-file
                                    :value="[]"
                                    accept="image/*"
                                    capture="environment"
                                    multiple="true"
                                    @change="files = [...files, ...$event]"
                                ></uploadable-file>
                            </template>
                        </div>

                        <div :class="$style['space-wrapper']">
                            <span>{{ files.length }} {{ $t('report_sheets.annexes_page.form.total_files') }}</span>
                            <files-preview v-if="files"
                                :files="files"
                            ></files-preview>
                        </div>

                        <ul :class="$style['buttons-wrapper']">
                            <li v-if="!annex?.report_sheet?.annexes_step_validated"><ion-button @click="showAnnexData = false">{{ $t('report_sheets.annexes_page.form.btn_cancel') }}</ion-button></li>
                            <li v-if="!annex?.report_sheet?.annexes_step_validated"><ion-button @click="saveAnnex()">{{ $t('report_sheets.annexes_page.form.btn_validate') }}</ion-button></li>
                            <li v-if="annex?.report_sheet?.annexes_step_validated"><ion-button @click="goToNextAnnex()">{{ annex?.order != totalAnnex ? $t('report_sheets.annexes_page.form.btn_next') : $t('report_sheets.annexes_page.form.btn_go_to_steps_page') }}</ion-button></li>
                        </ul>
                    </div>
                </div>
            </template>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import { defineComponent } from "vue"
    import {
        IonContent,
        IonHeader,
        IonPage,
        IonBackButton,
        IonTitle,
        IonToolbar,
        IonTextarea,
        IonLabel,
        IonButton,
    } from "@ionic/vue"

    import UploadableFile from "@/components/ui/fields/UploadableFile.vue"
    import FilesPreview from "@/components/data/files/FilesPreview.vue"
    import { Annex } from "@/annex";
    import EquipmentSkeleton from "@/components/skeletons/EquipmentSkeleton.vue"

    export default defineComponent({
        components: {
            UploadableFile,
            FilesPreview,
            IonTextarea,
            IonContent,
            IonHeader,
            IonTitle,
            IonToolbar,
            IonPage,
            EquipmentSkeleton,
            IonLabel,
            IonBackButton,
            IonButton
        },
        data() {
            return {
                loaded: false,
                annex: null as Annex | null,
                uuidNextStep: null,
                totalAnnex: null,
                showAnnexData: false,
                files: [] as any
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.loaded = false
                
                this.axios.get(`/report-sheets/${this.$route.params.uuidReport}/annexes/${this.$route.params.uuidAnnex}`)
                .then(response => {
                    this.annex = response.data.annex
                    this.uuidNextStep = response.data.uuidNextStep
                    this.totalAnnex = response.data.totalAnnex
                    this.files = response.data.annex.files

                    if (this.annex?.report_sheet?.annexes_step_validated)
                        this.showAnnexData = true
                }).catch(error => {
                    console.error(error)
                }).finally(() => {
                    this.loaded = true
                })
            },
            saveAnnex() {
                this.loaded = false

                this.axios.put(`/report-sheets/${this.$route.params.uuidReport}/annexes/${this.$route.params.uuidAnnex}`, {
                    comment: this.annex?.comment,
                    files: this.files
                }).then(async () => {
                    this.goToNextAnnex()
                }).catch(error => {
                    console.error(error)
                    this.loaded = true
                })
            },
            goToNextAnnex() {
                this.loaded = true

                if (this.uuidNextStep) {
                    this.$router.push(`/report-sheets/${this.$route.params.uuidReport}/annexes/${this.uuidNextStep}`)
                } else {
                    if (this.annex?.report_sheet?.annexes_step_validated)
                        this.$router.push(`/report-sheets/${this.$route.params.uuidReport}`)
                    else
                        this.$router.push(`/report-sheets/${this.$route.params.uuidReport}/annexes/validation`)
                }
            }
        }
    })
</script>

<style lang="scss" module>
    .sub-header {
        background: #000;
        height: 50px;
        width: 100%;
        display: grid;
        align-content: center;
        justify-content: center;
    }
    .annexes-wrapper {
        padding: 25px;

        h1 {
            text-align: center;
            margin-bottom: 50px;
        }
    }
    .buttons-wrapper {
        list-style: none;
        display: flex;
        align-items: center;
        justify-self: center;
        padding: 0;

        li {
            &:first-child {
                margin: 0 50px 0 0;
            }
            &:last-child {
                margin: 0;
            }
        }
    }
    .more-data-subtitle {
        text-decoration: underline;
    }
    .annexes-textarea {
        border: 1px solid rgba(#000, .1);
        border-radius: 12px;
        padding: 0 12px;
    }
    .space-wrapper {
        margin-bottom: 25px;
    }
</style>