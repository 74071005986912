<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>
          {{ $t("user.screen_title") }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content
        v-if="user.is_logged_in"
        :class="$style['wrapper']"
        class="ion-padding"
    >
      <ion-grid :fixed="true">
        <ion-row>
          <ion-col size="12" size-lg="4" size-md="12" size-sm="12">
            <h2>
              {{ $t("user.informations.title") }}
            </h2>
            <ion-item v-for="field in informations_fields" :key="field">
              <ion-label>{{ $t("user.informations." + field) }}</ion-label>
              <ion-label slot="end">
                <ion-text color="medium">
                  {{ user.getValue(field) }}
                </ion-text>
              </ion-label>
            </ion-item>
          </ion-col>
          <ion-col
              offset-lg="2"
              size="12"
              size-lg="4"
              size-md="12"
              size-sm="12"
          >
            <h2>
              {{ $t("user.credentials.title") }}
            </h2>

            <form @submit.prevent="changePassword">
              <ion-item v-if="user.has_password">
                <ion-label>
                  {{ $t("user.credentials.current_password") }}
                </ion-label>
                <ion-input
                    slot="end"
                    v-model="current_password"
                    type="password"
                ></ion-input>
              </ion-item>
                <ion-item>
                  <ion-input
                      name="new_password"
                      slot="end"
                      v-model="new_password"
                      type="password"
                      :label="$t('user.credentials.new_password')"></ion-input>
                </ion-item>
              <ion-item>



                <ion-input
                    name="new_password_verif"
                    slot="end"
                    v-model="new_password_verif"
                    type="password"
                    :label="$t('user.credentials.new_password_verif')"></ion-input>
              </ion-item>

              <ion-button
                  expand="full"
                  type="submit"
                  @click.prevent="changePassword"
              >
                {{ $t("user.credentials.change_password_submit") }}
              </ion-button>
            </form>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <ion-content v-else class="ion-padding">
      <UserSkeleton/>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  toastController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useUserStore } from "@/stores/user";

import UserSkeleton from "@/components/skeletons/UserSkeleton.vue";

import axios from "axios";

export default defineComponent({
  setup() {
    const user = useUserStore();

    return {user};
  },
  data() {
    return {
      informations_fields: ["first_name", "last_name", "email"],
      current_password: "",
      new_password: "",
      new_password_verif: "",
      loading: false,
    };
  },
  computed: {
    highlighted_section(): string | null {
      return this.$route.params?.section.toString();
    },
  },
  methods: {
    changePassword() {
      // Make the axios call
      this.loading = true;
      axios
          .put("user/password", {
            current_password: this.current_password,
            new_password: this.new_password,
            new_password_verif: this.new_password_verif,
          })
          .then(async () => {
            (
                await toastController.create({
                  header: this.$t("user.credentials.success_title"),
                  message: this.$t("user.credentials.success_message"),
                  duration: 2000,
                  color: "success",
                })
            ).present();

            this.loading = false;
            this.current_password = "";
            this.new_password = "";
            this.new_password_verif = "";
          })
          .catch(() => {
            this.loading = false;
          });
    },
  },
  components: {
    IonButtons,
    IonContent,
    IonPage,
    UserSkeleton,
    IonHeader,
    IonToolbar,
    IonTitle,

    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonItem,
    IonInput,
    IonButton,
    IonLabel, IonMenuButton
  },
});
</script>


<style lang="scss" module>
.wrapper {
  ion-item {
    --padding-start: 0;
  }
}
</style>