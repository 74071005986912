import { defineStore } from "pinia";

import { Organization } from '@/users';

export const useUserStore = defineStore("user", {
    state: () => {
        return {
            uuid: null,
            email: null,
            first_name: null,
            last_name: null,
            rights: [],
            sites_supervisor: [],
            has_password: false,
            loaded: false,
            organizations: [],
            current_organization: null,
            profile_picture: null,
        } as {
            uuid: string | null;
            email: string | null;
            first_name: string | null;
            last_name: string | null;
            rights: string[];
            sites_supervisor: number[];
            has_password: boolean;
            loaded: boolean;
            organizations: Organization[];
            current_organization: Organization | null;
            profile_picture: string | null;
        };
    },
    getters: {
        is_logged_in: (state) => {
            return !!state.email;
        },
        display_name: (state) =>
            state.first_name && state.last_name
                ? state.first_name + " " + state.last_name
                : state.email,
        hasRight: (state) => (right: string) => {
            return state.rights.includes(right);
        },
        getValue: (state) => (key: string) => {
            return (state as any)[key] ?? null;
        },
    },
});
